.job-match-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .top-title {
    display: flex;
    gap: 10px;

    span:first-child {
      color: #676767;
    }
  }

  .job-match-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .job-match-top {
      display: flex;
      gap: 10px;

      .grid-cards {
        width: 40%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        .card {
          background-color: #ffffff;
          border-radius: 12px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          text-align: center;
          .job-row {
            display: flex;
            gap: 10px;

            .icon-container {
              background-color: #f2f9ff;
              border-radius: 8px;
              padding: 5px 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              .card-icon {
                color: #145c9e;
              }
            }

            span {
              font-size: 24px;
              font-weight: bold;
            }
          }
          .grey-text {
            font-size: 14px;
            color: #999999;
            display: flex;
            gap: 10px;
            justify-content: center;
          }
        }
      }

      .job-match-chart {
        width: 60%;
        background-color: #ffffff;
        border-radius: 12px;
      }
    }

    .job-match-bottom {
      display: flex;
      gap: 10px;

      .job-matches,
      .recent-signups {
        width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .top-row {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          .view-all {
            display: flex;
            align-items: center;
            color: #f87c47;
            cursor: pointer;
            gap: 5px;
            font-weight: bold;
          }
        }

        .table-container {
          table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;

            thead {
              tr {
                th {
                  font-size: 14px;
                  color: #808080;
                  font-weight: 500;
                  padding-inline: 20px;
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 5px 10px;
                  font-size: 14px;

                  .type {
                    background-color: #fff7f4;
                    padding: 5px 15px;
                    border-radius: 8px;
                    color: #f87c47;
                    width: fit-content;
                    display: block;
                  }
                  .date {
                    font-size: 12px;
                    color: #919393;
                  }
                }

                .job-posting-td {
                  .company {
                    font-size: 12px;
                    color: #919393;
                  }
                }

                .application-date-td {
                  .time {
                    font-size: 12px;
                    color: #919393;
                  }
                }

                .status-td {
                  .status {
                    background-color: #fff7f4;
                    padding: 5px 15px;
                    border-radius: 8px;
                    color: #f87c47;
                    width: fit-content;
                    display: block;
                  }
                }

                #action {
                  text-align: center;
                  .dots {
                    cursor: pointer;
                  }
                }
                .applicant-td {
                  display: flex;
                  gap: 10px;
                  align-items: center;

                  #initial {
                    padding: 10px;
                    background-color: #fcf1ec;
                    color: #f87c47;
                    border-radius: 50%;
                    font-size: 12px;
                  }

                  #col {
                    display: flex;
                    flex-direction: column;

                    .email {
                      font-size: 12px;
                      color: #919393;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .job-match-container {
    .job-match-main-container {
      .job-match-bottom {
        .job-matches,
        .recent-signups {
          .table-container {
            table {
              tbody {
                tr {
                  td {
                    padding: 5px 5px 5px 0px;
                    .email {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .job-match-container {
    .job-match-main-container {
      .job-match-top {
        flex-direction: column;

        .grid-cards {
          width: 100%;
        }
        .job-match-chart {
          width: 100%;
        }
      }
      .job-match-bottom {
        flex-direction: column;
        .job-matches,
        .recent-signups {
          .table-container {
            table {
              tbody {
                tr {
                  td {
                    .email {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .job-match-container {
    .job-match-main-container {
      .job-match-bottom {
        .job-matches,
        .recent-signups {
          .table-container {
            table {
              tbody,
              thead {
                tr {
                  .status-td,
                  .status-th {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .job-match-container {
    .job-match-main-container {
      .job-match-bottom {
        .application-date-td,
        .application-date-th {
          display: none;
        }
        .job-matches,
        .recent-signups {
          padding: 15px;
          .table-container {
            table {
              tbody {
                tr {
                  td {
                    .email {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .job-match-container {
    .job-match-main-container {
      .job-match-top {
        flex-direction: column;

        .grid-cards {
          width: 100%;
          .card {
            .job-row {
              .icon-container {
                padding: 5px 5px;
                .card-icon {
                  height: 15px;
                  width: 20px;
                }
              }
              span {
                font-size: 20px;
              }
            }
            .grey-text {
              p {
                font-size: 12px;
                text-align: start;
              }
            }
          }
        }
        .job-match-chart {
          width: 100%;
        }
      }
    }
  }
}
