.dashboard-side-bar {
  background-color: #145C9E;
  height: 100vh;
  overflow: auto;
  max-width: 276px;
  color: #A0CAF0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
}
.dashboard-side-bar a {
  color: inherit;
}
.dashboard-side-bar .top,
.dashboard-side-bar .bottom {
  padding: 24px;
}
.dashboard-side-bar .close-icon {
  display: none;
}
.dashboard-side-bar .branding {
  height: 60px;
  display: flex;
  align-items: center;
}
.dashboard-side-bar .branding .site-title {
  font-size: 24px;
  color: #fff;
}
.dashboard-side-bar .branding .site-title span {
  color: #F87C47;
}
.dashboard-side-bar .overview {
  padding-bottom: 24px;
}
.dashboard-side-bar .overview .menu-link,
.dashboard-side-bar .overview .title,
.dashboard-side-bar .settings .menu-link,
.dashboard-side-bar .settings .title {
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 12px;
}
.dashboard-side-bar .overview .menu-link .icon,
.dashboard-side-bar .overview .title .icon,
.dashboard-side-bar .settings .menu-link .icon,
.dashboard-side-bar .settings .title .icon {
  color: #A0CAF0;
}
.dashboard-side-bar .overview .active,
.dashboard-side-bar .settings .active {
  padding: 10px 16px;
}
.dashboard-side-bar .training-center .title,
.dashboard-side-bar .job-match .title,
.dashboard-side-bar .canteen .title {
  justify-content: space-between;
}

.modules {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
.modules .module {
  display: grid;
  gap: 16px;
  height: -moz-fit-content;
  height: fit-content;
}
.modules .module .links {
  display: grid;
  gap: 12px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}
.modules .module .menu-link {
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
}
.modules .module .open {
  max-height: 500px;
  padding-bottom: 24px;
}
.modules .module .submenu-open .icon {
  animation: subMenuOpen 0.3s linear forwards;
}
@keyframes subMenuOpen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.text {
  font-size: 16px;
  font-weight: normal;
}

.links .menu-link {
  gap: 6px;
}
.links text {
  font-size: 12px;
}

.divider {
  color: #1B63A6;
  border-color: #1B63A6;
  height: 2px;
  background-color: #1B63A6;
}

.title {
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  margin-bottom: -12px;
}

.logout {
  justify-content: start;
}

.active {
  background-color: #F87C47;
  padding: 12px 16px;
  border-radius: 3px;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .dashboard-side-bar {
    display: none;
  }
  .side-bar-open {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    z-index: 100;
    animation: animateSideBar ease-in-out 0.3s forwards;
  }
  .side-bar-open .close-icon {
    display: block;
    position: absolute;
    top: 32px;
    right: 20px;
  }
  .side-bar-closed {
    animation: animateSideBarBack ease-in-out 0.3s forwards;
  }
  @keyframes animateSideBar {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes animateSideBarBack {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
}/*# sourceMappingURL=sideBar.css.map */