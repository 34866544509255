.actions-popup-menu {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  display: grid;
  place-content: center;
  justify-content: right;
  height: 100vh;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.actions-popup-menu .actions-popup {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  margin-right: 48px;
  border-radius: 8px;
}
.actions-popup-menu .actions-popup button {
  background: transparent;
  display: flex;
  align-items: center;
  gap: 6px;
}

.leave-details-popup {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  display: grid;
  place-content: center;
  height: 100vh;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.leave-details-popup small {
  color: gray;
}
.leave-details-popup .popup-content {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  padding: 24px;
  width: 600px;
  animation: ease showLeavePopup 0.3s forwards;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.leave-details-popup .popup-content .title-status,
.leave-details-popup .popup-content .leave-info,
.leave-details-popup .popup-content .employee-details,
.leave-details-popup .popup-content .leave-reason {
  border: 1px solid whitesmoke;
  padding: 24px;
  border-radius: 6px;
}
.leave-details-popup .popup-content .title-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leave-details-popup .popup-content .title-status .status {
  background-color: #145c9e;
  color: white;
  padding: 6px 12px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 12px;
  border-radius: 6px;
}
.leave-details-popup .popup-content .title-status .status .dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: white;
}
.leave-details-popup .popup-content .leave-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 16px;
}
.leave-details-popup .popup-content .leave-info .info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: 1fr 1fr;
}
.leave-details-popup .popup-content .leave-info .info .label {
  display: flex;
  align-content: center;
  gap: 6px;
}
@keyframes showLeavePopup {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.leave-requests-data {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.leave-requests-data .leave-request {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  gap: 24px;
  background-color: #fafafa;
  padding: 8px 16px;
  border-radius: 8px;
}
.leave-requests-data .leave-request .col-profile {
  display: flex;
  align-items: center;
  gap: 24px;
}
.leave-requests-data .leave-request .col-profile .user-names {
  background-color: #fff7f3;
  padding: 24px;
  border-radius: 50%;
}
.leave-requests-data .leave-request .col-profile .user-names p {
  font-weight: bold;
  font-size: 14px;
  color: #f87c47;
}
.leave-requests-data .leave-request .col-profile img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.leave-requests-data .leave-request .col-profile h4 {
  font-size: 14px;
}
.leave-requests-data .leave-request .col-profile p {
  color: #979797;
  font-size: 12px;
}
.leave-requests-data .leave-request .col-profile .user-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.leave-requests-data .leave-request .col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.leave-requests-data .leave-request .col .title {
  font-size: 14px;
  color: #979797;
  font-weight: 400;
}
.leave-requests-data .leave-request .col p {
  font-size: 12px;
}
.leave-requests-data .leave-request .col-btns {
  display: flex;
  gap: 6px;
}
.leave-requests-data .leave-request .col-btns .close-btn,
.leave-requests-data .leave-request .col-btns .actions-btn {
  padding: 12px;
}
.leave-requests-data .leave-request .col-btns .close-btn {
  background-color: #fff4d9;
  color: #ffb708;
  font-weight: bold;
  font-size: 18px;
}
.leave-requests-data .leave-request .col-btns .actions-btn {
  background-color: #f3f3f3;
}
.leave-requests-data .leave-request .col-btns .accept-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
  background-color: #f87c47;
  color: #ffffff;
}/*# sourceMappingURL=leave-requests.css.map */