.requesting-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.requesting-container .requesting-dots {
  display: flex;
}
.requesting-container .requesting-dot {
  display: inline-block;
  animation: loadingAnimation 1s infinite;
}
.requesting-container .requesting-dot:nth-child(1) {
  animation-delay: 0.1s;
}
.requesting-container .requesting-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.requesting-container .requesting-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.leaves-section .popup .add-leave {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.leaves-section .popup .add-leave .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leaves-section .popup .add-leave .title button {
  background: transparent;
  font-size: 24px;
}
.leaves-section .popup .add-leave form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.leaves-section .popup .add-leave form .date-input-fields {
  display: flex;
  gap: 16px;
}
.leaves-section .popup .add-leave form .date-input-fields input {
  width: 255px;
}
.leaves-section .popup .add-leave form .btns {
  display: flex;
  gap: 24px;
  justify-content: right;
}
.leaves-section .popup .add-leave form .btns button {
  padding: 16px;
}
.leaves-section .popup .add-leave form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.leaves-section .popup .add-leave form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.leaves-section .row {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.leaves-section .row .underline {
  background-color: #f1f1f1;
  width: 100%;
  height: 1px;
}
.leaves-section .row .tabs {
  background-color: #ffffff;
  margin: 15px 30px;
  padding: 15px 10px;
  border-radius: 8px;
  display: flex;
  gap: 48px;
}
.leaves-section .row .tabs button {
  background: transparent;
  font-size: 16px;
  color: #8a8a8a;
  display: flex;
  gap: 6px;
  align-items: center;
}
.leaves-section .row .tabs .icon {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  display: block;
}
.leaves-section .row .tabs .leave-requests-number {
  background-color: #f87c47;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  display: block;
  color: #ffffff;
}
.leaves-section .row .tabs .active {
  color: #f87c47;
}
.leaves-section .row .numbers {
  background-color: #ffffff;
  margin: 15px 30px;
  padding: 15px 40px;
  border-radius: 8px;
}
.leaves-section .row .numbers .numbers-details {
  display: flex;
  gap: 40px;
}
.leaves-section .row .numbers .numbers-details .col-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.leaves-section .row .numbers .numbers-details .col-details .col-details-numbers {
  display: flex;
  align-items: center;
  gap: 6px;
}
.leaves-section .row .numbers .numbers-details .col-details .col-details-numbers .percentage {
  background-color: #dbf2e7;
  padding: 4px 8px;
  border-radius: 8px;
}
.leaves-section .row .numbers .numbers-details .col-details .col-details-numbers .percentage p {
  color: #01a653;
  font-size: 12px;
  text-align: center;
  padding: 0;
}
.leaves-section .row .numbers .numbers-details .col-details p {
  color: #979797;
}
.leaves-section .row .numbers .numbers-details .col-details:nth-child(2),
.leaves-section .row .numbers .numbers-details .col-details:nth-child(3),
.leaves-section .row .numbers .numbers-details .col-details:nth-child(4),
.leaves-section .row .numbers .numbers-details .col-details:nth-child(5),
.leaves-section .row .numbers .numbers-details .col-details:nth-child(6),
.leaves-section .row .numbers .numbers-details .col-details:nth-child(7) {
  border-left: 3px solid #f6f6f6;
}
.leaves-section .row .numbers .numbers-details .col-details:nth-child(2) h4,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(3) h4,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(4) h4,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(5) h4,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(6) h4,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(7) h4 {
  padding-left: 12px;
}
.leaves-section .row .numbers .numbers-details .col-details:nth-child(2) p,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(3) p,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(4) p,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(5) p,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(6) p,
.leaves-section .row .numbers .numbers-details .col-details:nth-child(7) p {
  padding-left: 12px;
}
.leaves-section .row .contents-container .title-contents .top .col {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.leaves-section .row .contents-container .title-contents .top .col p {
  color: #979797;
}
.leaves-section .row .contents-container .title-contents .top .col span {
  color: #f87c47;
}
.leaves-section .row .contents-container .title-contents .top .col button {
  background: transparent;
  padding: 10px 24px;
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
}
.leaves-section .row .contents-container .title-contents .top .col .icon {
  color: #8a8a8a;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns {
  display: flex;
  align-items: center;
  gap: 12px;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .calendar-list-btns {
  display: flex;
  align-items: center;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .calendar-list-btns button {
  border: transparent;
  padding: 4px 8px;
  font-size: 24px;
  border-radius: 0px;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .calendar-list-btns .list-btn {
  border: 1px solid #8a8a8a;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .calendar-list-btns .calendar-btn {
  border: 1px solid #8a8a8a;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .calendar-list-btns .active {
  background-color: #f87c47;
  color: #ffffff;
  border: 1px solid #f87c47;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .add-leave-btn {
  border: transparent;
  background-color: #f87c47;
  color: #ffffff;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .add-leave-btn span {
  color: #ffffff;
}
.leaves-section .row .contents-container .title-contents .top .col .leave-btns .recent-btn span {
  color: #8a8a8a;
}
.leaves-section .row .contents-container .title-contents .input-fields {
  padding-top: 10px;
}
.leaves-section .row .contents-container .title-contents .input-fields .input {
  position: relative;
}
.leaves-section .row .contents-container .title-contents .input-fields .input input {
  padding-left: 10px;
  padding-right: 50px;
}
.leaves-section .row .contents-container .title-contents .input-fields .input .icon {
  position: absolute;
  left: 220px;
  top: 11px;
  color: #979797;
}
.leaves-section .row .contents-container .table-contents p {
  color: #145c9e;
  font-size: 20px;
}

@media screen and (max-width: 540px) {
  .leaves-section .popup .add-leave {
    width: 100%;
  }
  .leaves-section .popup .add-leave form .date-input-fields {
    flex-direction: column;
  }
  .leaves-section .popup .add-leave form .date-input-fields input {
    width: 100%;
  }
  .leaves-section .row .tabs {
    margin: 15px 10px;
  }
  .leaves-section .row .numbers {
    margin: 15px 10px;
  }
  .leaves-section .row .numbers .numbers-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .leaves-section .row .numbers .numbers-details .col-details h4 {
    font-size: 14px;
  }
  .leaves-section .row .numbers .numbers-details .col-details p {
    font-size: 12px;
  }
  .leaves-section .row .numbers .numbers-details .col-details:nth-child(3) {
    border-left: none;
  }
  .leaves-section .row .numbers .numbers-details .col-details:nth-child(3) h4 {
    padding-left: 0;
  }
  .leaves-section .row .numbers .numbers-details .col-details:nth-child(3) p {
    padding-left: 0;
  }
  .leaves-section .row .contents-container .top .leave-btns .text {
    display: none;
  }
  .leaves-section .row .contents-container .top .leave-btns span {
    font-size: 18px;
  }
}/*# sourceMappingURL=leave-record.css.map */