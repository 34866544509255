$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.employees-section {
  .popup {
    place-content: center;

    .add-employee {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;

      .title {
        display: flex;
        justify-content: space-between;

        button {
          background: transparent;
          font-size: 24px;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        label {
          font-size: 14px;
        }

        .input-fields {
          .field {
            input,
            select {
              width: 200px;
              outline: none;
            }
          }
        }

        .radio-inputs {
          display: flex;
          gap: $big_gap;

          .radio-input-field {
            display: flex;
            gap: 6px;
          }
        }

        .btns {
          display: flex;
          gap: 24px;
          justify-content: right;

          button {
            padding: 16px;
          }

          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $gray_color;
          }

          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }

    .add-leave {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0px;
        button {
          background: transparent;
          font-size: 24px;
          padding: 10px;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;
        width: 100%;
        .input-fields {
          .field {
            input,
            select {
              width: 200px;
              outline: none;
              color: $gray_color;
            }
          }
        }
        select,
        .comments-section textarea {
          width: 100%;
          outline: none;
          font-size: 14px;
          color: $gray_color;
        }
        .date-input-fields {
          display: flex;
          justify-content: space-between;
          .date-input {
            width: 48%;
            &:first-child::before {
              content: "Start Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            &:last-child::before {
              content: "End Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            input {
              width: 100%;
              color: $gray_color;
              outline: none;
            }
          }
        }
        .comments-section textarea {
          width: 100%;
          resize: none;
          color: $gray_color;
        }
        .btns {
          display: flex;
          width: 100%;
          gap: 20px;
          button {
            padding: 16px;
            width: 100%;
          }
          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $gray_color;
          }
          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }

    .performance {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 12px;
        h3 {
          color: $primary_color;
          font-size: 26px;
          font-weight: 600;
        }
        p {
          color: $gray_color;
          font-size: 14px;
        }
        button {
          background: transparent;
          font-size: 24px;
          padding: 10px;
          align-self: baseline;
        }
      }
      .title2 {
        display: flex;
        flex-direction: row;
        padding: 0px;
        gap: 12px;
        h3 {
          color: black;
          font-size: 22px;
          font-weight: 600;
        }
        p {
          color: $gray_color;
          font-size: 14px;
          padding-top: 6px;
        }
        button {
          background: transparent;
          font-size: 24px;
          padding: 10px;
          align-self: baseline;
        }
      }
      .progress-container {
        height: 10px;
        background-color: grey;
        border-radius: 5px;
        .progress-bar {
          height: 100%;
          background-color: $secondary_color;
          border-radius: 5px;
          transition: width 0.3s ease;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;
        width: 100%;
        .form-step {
          display: flex;
          flex-direction: column;
          gap: $big_gap;
        }
        .input-fields {
          padding-top: 10px;
          .field {
            input,
            select {
              outline: none;
              color: $gray_color;
            }
          }
          .input {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            label {
              font-size: 14px;
              font-weight: 500;
            }
            input {
              padding-left: 40px;
              padding-right: 0px;
              outline: none;
            }
            .icon {
              position: absolute;
              left: 15px;
              top: 44px;
              color: $gray_color;
            }
          }
        }
        .selectOption {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .title2 {
            display: block;
            h3 {
              font-size: 18px;
              font-weight: 600;
            }
          }
          .selectOptions {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
            gap: 10px;
          }
          option {
            background-color: #f9f9f9;
            padding: 12px 24px;
          }
        }
        .employee-performance {
          display: flex;
          flex-direction: column;
          gap: 30px;
          border-radius: 12px;
          .title {
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            justify-content: flex-start;
            align-items: center;
            border-radius: 12px;
            background-color: $light_gray;
            span {
              background-color: #fff7f3;
              color: $secondary_color;
              font-weight: bold;
              padding: 8px;
              border-radius: 50%;
            }
            div {
              display: block;
              position: relative;
              left: 0;
              p {
                color: black;
                font-weight: 400;
                font-size: 14px;
              }
              small {
                color: #666666;
                font-weight: 300;
                font-size: 12px;
              }
            }
          }
          .ratings {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            .ratings-stars {
              display: block;
              background-color: #fff5f1;
              padding: 10px 20px;
              gap: 10px;
              border-radius: 12px;
              p {
                color: black;
                font-weight: 500;
                font-size: 16px;
              }
              .star {
                font-size: 20px;
                color: #545352;
                font-family: "Outfit", sans-serif;
              }
              .starfull {
                font-size: 20px;
                color: $secondary_color;
                font-family: "Outfit", sans-serif;
              }
            }
            .ratings-bttn {
              display: flex;
              flex-direction: row;
              background-color: #fff5f1;
              padding: 18px 24px;
              gap: 10px;
              p {
                background-color: #fff7f3;
                color: $secondary_color;
                font-weight: bold;
                padding: 8px;
                border-radius: 50%;
              }
              h5 {
                color: $secondary_color;
                font-weight: 400;
                font-size: 14px;
                padding: 0px;
                padding-top: 10px;
              }
            }
          }
          .checkIf {
            display: flex;
            flex-direction: column;
            gap: 10px;
            label {
              color: black;
              font-weight: 500;
              font-size: 16px;
            }
            .ratings-bttn {
              background-color: #f7f7f7;
              display: flex;
              box-sizing: border-box;
              justify-content: flex-start;
              align-items: center;
              code {
                background-color: white;
                height: 20px;
                width: 20px;
              }
              p {
                color: #545352;
                font-weight: 400;
                font-size: 14px;
                background-color: #f7f7f7;
                padding: 0px;
              }
            }
            .selected {
              cursor: pointer;
              background-color: #f0f0f0;
              code {
                background-color: #fff5f1;
                border-radius: 10px;
              }
              p {
                color: #414040;
              }
            }
          }
        }
        .comments-section {
          label {
            font-size: 14px;
            font-weight: 500;
          }
        }
        select,
        .comments-section textarea {
          width: 100%;
          outline: none;
          font-size: 14px;
          color: $gray_color;
        }
        .date-input-fields {
          display: flex;
          justify-content: space-between;
          .date-input {
            width: 48%;
            &:first-child::before {
              content: "Start Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            &:last-child::before {
              content: "End Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            input {
              width: 100%;
              color: $gray_color;
              outline: none;
            }
          }
        }
        .comments-section textarea {
          width: 100%;
          resize: none;
          color: $gray_color;
        }
        .table-contents{
          padding: 0%;
        }
        .btns {
          display: flex;
          width: 100%;
          gap: 20px;
          button {
            padding: 16px;
            width: 100%;
          }
          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $secondary_color;
          }
          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }
  }

  .underline {
    background-color: #f1f1f1;
    width: 100%;
    height: 1px;
  }

  .contents-container {
    .title-contents {
      .top {
        .col-title {
          p {
            color: $gray_color;
          }

          button {
            background-color: $secondary_color;
            padding: 10px 24px;
            color: $white_color;
          }
        }
      }

      .input-fields {
        padding-top: 10px;

        .input {
          position: relative;

          input {
            padding-left: 15px;
            padding-right: 60px;
            outline: none;
          }

          .icon {
            position: absolute;
            left: 220px;
            top: 15px;
            color: $gray_color;
          }
        }
      }
    }
  }
}

.col-2 {
  width: 100%;
}

// responsiveness
@media screen and (max-width: 810px) {
  .employees-section {
    .popup {
      .add-employee {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .employees-section {
    .popup {
      .add-employee {
        width: 100%;
      }
    }
    .row {
      .contents-container {
        .title-contents {
          .top {
            .input-fields {
              .input {
                input {
                  width: 100%;
                  outline: none;
                }
              }
            }

            .col-title {
              button {
                width: 240px;
              }
            }
          }
        }
      }
    }
  }
}
