.popup .assign-task {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.popup .assign-task .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup .assign-task .title .icon button {
  background: transparent;
  font-size: 20px;
}
.popup .assign-task form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.popup .assign-task form textarea {
  resize: none;
}
.popup .assign-task form .btns {
  display: flex;
  align-items: center;
  gap: 16px;
}
.popup .assign-task form .btns button {
  padding: 16px 64px;
}
.popup .assign-task form .btns .cancel-btn {
  background: transparent;
  border: 1px solid #ebebeb;
  color: #979797;
}
.popup .assign-task form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}

.edit-task-popup {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  display: grid;
  place-items: center;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.edit-task-popup .edit-task {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  width: 40vw;
}
.edit-task-popup .edit-task .title {
  display: flex;
  justify-content: space-between;
}
.edit-task-popup .edit-task .title button {
  background: transparent;
  font-size: 24px;
}
.edit-task-popup .edit-task form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.edit-task-popup .edit-task form .field {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.edit-task-popup .edit-task form .field label {
  font-size: 14px;
}
.edit-task-popup .edit-task form .field select {
  color: #979797;
}
.edit-task-popup .edit-task form .field textarea {
  resize: none;
  height: 235px;
  width: 100%;
}
.edit-task-popup .edit-task form .field input {
  padding: 16px 8px;
}
.edit-task-popup .edit-task form .field select {
  padding: 16px 8px;
}
.edit-task-popup .edit-task form .btns {
  display: flex;
  gap: 24px;
}
.edit-task-popup .edit-task form .btns button {
  padding: 16px;
}
.edit-task-popup .edit-task form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.edit-task-popup .edit-task form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}

.contents-container {
  background: transparent;
}
.contents-container .project-details-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contents-container .project-details-container .project-details {
  display: flex;
  justify-content: space-between;
}
.contents-container .project-details-container .project-details .col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contents-container .project-details-container .project-details .col .col-top {
  display: flex;
  align-items: center;
  gap: 40px;
}
.contents-container .project-details-container .project-details .col .col-top h5 {
  color: #979797;
  font-size: 16px;
}
.contents-container .project-details-container .project-details .col .col-top .project-title {
  display: flex;
  align-items: center;
  gap: 12px;
}
.contents-container .project-details-container .project-details .col .col-top .project-title .icon {
  background-color: #fff7f3;
  padding: 10px;
  font-size: 25px;
  color: #f87c47;
  border-radius: 8px;
}
.contents-container .project-details-container .project-details .col .col-top .project-title .title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contents-container .project-details-container .project-details .col .col-top .project-title .title p {
  color: #979797;
}
.contents-container .project-details-container .project-details .col .col-top .timeline {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contents-container .project-details-container .project-details .col .col-top .timeline h5 {
  font-weight: 500;
}
.contents-container .project-details-container .project-details .col .col-top .timeline p {
  font-weight: bold;
}
.contents-container .project-details-container .project-details .col .col-top .department {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.contents-container .project-details-container .project-details .col .col-top .department h5 {
  font-weight: 500;
}
.contents-container .project-details-container .project-details .col .col-top .department p {
  font-weight: bold;
}
.contents-container .project-details-container .project-details .col .description {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contents-container .project-details-container .project-details .col .description h5 {
  color: #979797;
  font-size: 18px;
  font-weight: 500;
}
.contents-container .project-details-container .project-details .col .description p {
  width: 70%;
}
.contents-container .project-details-container .project-details .col button {
  padding: 12px 40px;
}
.contents-container .project-details-container .project-details .col .status-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.contents-container .project-details-container .project-details .col .assign-btn {
  background-color: #fff7f3;
  color: #f87c47;
}
.contents-container .project-details-container .tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contents-container .project-details-container .tabs .tasks {
  display: flex;
  align-items: center;
  gap: 12px;
}
.contents-container .project-details-container .tabs .tasks .task-count {
  background-color: #fff7f3;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  color: #f87c47;
}
.contents-container .project-details-container .tabs .input-fields {
  padding-top: 10px;
}
.contents-container .project-details-container .tabs .input-fields .input {
  position: relative;
}
.contents-container .project-details-container .tabs .input-fields .input input {
  padding-left: 15px;
  padding-right: 60px;
}
.contents-container .project-details-container .tabs .input-fields .input .icon {
  position: absolute;
  left: 220px;
  top: 11px;
  color: #979797;
}
.contents-container .project-details-container .tabs .btns {
  display: flex;
  align-items: center;
  gap: 16px;
}
.contents-container .project-details-container .tabs .btns button {
  padding: 10px 24px;
}
.contents-container .project-details-container .tabs .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.contents-container .project-details-container .tabs .btns .recent-btn {
  background: transparent;
  color: #807f7f;
  border: 1px solid #979797;
}
.contents-container .tasks-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.contents-container .tasks-details .task {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
}
.contents-container .tasks-details .task .task-status {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contents-container .tasks-details .task .task-status .task-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contents-container .tasks-details .task .task-status .task-actions button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.contents-container .tasks-details .task .task-status .task-actions .mark-complete {
  background-color: rgba(20, 91, 158, 0.231372549);
  color: #145c9e;
}
.contents-container .tasks-details .task .task-status .task-actions .mark-complete:hover {
  background-color: #145c9e;
  color: white;
}
.contents-container .tasks-details .task .task-status .task-actions .view-details {
  background-color: rgba(248, 124, 71, 0.2078431373);
  color: #f87c47;
}
.contents-container .tasks-details .task .task-status .task-actions .view-details:hover {
  background-color: #f87c47;
  color: white;
}
.contents-container .tasks-details .task .task-status .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contents-container .tasks-details .task .task-status .status .status-name {
  background-color: #eaf5ff;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 16px;
  border-radius: 8px;
}
.contents-container .tasks-details .task .task-status .status .icon .open-task-details-btn {
  background: transparent;
}
.contents-container .tasks-details .task .task-status .status p {
  color: #145c9e;
  font-size: 14px;
}
.contents-container .tasks-details .task .task-status .status .dot {
  width: 10px;
  height: 10px;
  background-color: #145c9e;
  border-radius: 50%;
}
.contents-container .tasks-details .task .task-status .task-description {
  color: #979797;
  font-weight: 200;
}
.contents-container .tasks-details .task .task-status .task-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #979797;
}
.contents-container .tasks-details .task .task-status .task-row .col .assignees {
  display: flex;
}
.contents-container .tasks-details .task .task-status .task-row .col .assignees .assignee {
  padding: 8px;
  border-radius: 50%;
}
.contents-container .tasks-details .task .task-status .task-row .col .assignees .assignee p {
  font-weight: bold;
}
.contents-container .tasks-details .task .task-status .task-row .col .assignees .assignee:nth-child(odd) {
  background-color: #f2f2f2;
  color: #ffbb38;
}
.contents-container .tasks-details .task .task-status .task-row .col .assignees .assignee:nth-child(even) {
  background-color: #bfdcf6;
  color: #145c9e;
}
.contents-container .tasks-details .task .task-status .task-row p {
  font-weight: 200;
}/*# sourceMappingURL=projectDetails.css.map */