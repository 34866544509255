.cohort-popup {
  margin-left: auto;
  max-width: 600px;
  background-color: white;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  z-index: 100;
  display: block;
  overflow-y: auto;
 
  .close-icon{
   align-items: flex-end;
   display: flex;
   justify-content: flex-end;
   margin-left: auto;
   margin-bottom: 15px;
  }

  .applicant-info{
   display: grid;
   gap: 40px;
    .applicant-header{
      display: flex;
      justify-content: space-between;
       align-items: center;
      h3{
        font-size: 20px;
        font-weight: 600;
      }
    p{
      font-size: 16px;
      color: #9B9B9B;
    }
    .name-date{
      display: grid;
      gap: 8px;
    }

    .status-action{
      display: flex;
      gap: 7px;
      align-items: center;

      .status, .action{
        display: flex;
        gap: 10px;
        align-items: center;
        background-color: #F9F9F9;
        width: fit-content;
        padding: 10px;
        height: 44px;
        border-radius: 8px;
        justify-content: center;
      }
      p, h4{
        font-weight: 400;
      }

      h4{
        color: #145C9E;
        font-size: 16px;
      }
    }
    }
  }

  .cohort-background{
    display: grid;
    gap: 20px;

    .cohort-text{
      display: flex;
      align-items: center;
      gap: 40px;

      .text-icon{
        display: flex;
        align-items: center;
        gap: 10px;
        p{
          color: #727272;
        }
      }
      h2{
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .training-reminder{
    display: flex;
    justify-content: space-between;
    border: 1px solid #F6F6F6;
    border-radius: 12px;
    padding: 12px 14px 12px 14px;
    align-items: center;

    .training-payed{
      display: flex;
      gap: 10px;
      align-items: center;
      p{
        font-weight: 500;
      }

      h4{
        font-size: 14px;
        font-weight: 400;
        color: #145C9E;
        background-color: #EFF7FF;
        padding: 6px 16px 6px 16px;
        border-radius: 8px;
      }
    }

    .icon-reminder{
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: #FFECE4;
      border-radius: 8px;
      padding: 8px 16px 8px 16px;
      color: #F87C47;
    }
  }

  .taby{
    margin-top: 30px;
    background-color: #FAFAFA;
    display: flex;
    padding: 12px;
    border-radius: 12px;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;

    .tab-button{
      display: flex;
      align-items: center;
      gap: 10px;
      color: #8A8A8A;
      cursor: pointer;

      &.active{
        background-color: #FEEEE7;
        color: #F87C47 !important;
        padding: 16px;
        border-radius: 12px;
      }
    }
  }

  .other-info{
    display: grid;
    gap: 40px;
    padding: 10px;
    .personal{
      h1{
        font-size: 16px;
        font-weight: 500;
      }
      p{
        font-weight: 400;
        color: #B6B6B6;
      }
      .name-phone{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        padding-top: 15px;
        .text-info{
          display: grid;
          gap: 10px;
         
        }
      }
    }
  }
}

.cohort-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
