$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.popup {
    .assign-task {
        background-color: $white_color;
        padding: 24px;
        border-radius: $border_radius;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon {
                button {
                    background: transparent;
                    font-size: 20px;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            textarea {
                resize: none;
            }

            .btns {
                display: flex;
                align-items: center;
                gap: 16px;

                button {
                    padding: 16px 64px;
                }

                .cancel-btn {
                    background: transparent;
                    border: 1px solid #ebebeb;
                    color: $gray_color;
                }

                .add-btn {
                    background-color: $secondary_color;
                    color: $white_color;
                }
            }
        }
    }
}

.edit-task-popup {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-items: center;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .edit-task {
        background-color: $white_color;
        padding: 24px;
        border-radius: $border_radius;
        display: grid;
        height: fit-content;
        gap: 12px;
        width: 40vw;

        .title {
            display: flex;
            justify-content: space-between;

            button {
                background: transparent;
                font-size: 24px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .field {
                display: flex;
                flex-direction: column;
                gap: calc($medium_gap / 2);

                label {
                    font-size: 14px;
                }

                select {
                    color: $gray_color;
                }

                textarea {
                    resize: none;
                    height: 235px;
                    width: 100%;
                }

                input {
                    padding: 16px 8px;
                }

                select {
                    padding: 16px 8px;
                }
            }

            .btns {
                display: flex;
                gap: 24px;

                button {
                    padding: 16px;
                }

                .cancel-btn {
                    border: 1px solid #ebebeb;
                    background: transparent;
                    color: $gray_color;
                }

                .add-btn {
                    background-color: $secondary_color;
                    color: $white_color;
                }
            }
        }
    }
}

.contents-container {
    background: transparent;

    .project-details-container {
        background-color: $white_color;
        border-radius: $border_radius;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        .project-details {
            display: flex;
            justify-content: space-between;

            .col {
                display: flex;
                flex-direction: column;
                gap: $big_gap;

                .col-top {
                    display: flex;
                    align-items: center;
                    gap: 40px;

                    h5 {
                        color: $gray_color;
                        font-size: 16px;
                    }

                    .project-title {
                        display: flex;
                        align-items: center;
                        gap: $medium_gap;

                        .icon {
                            background-color: $light_orange;
                            padding: 10px;
                            font-size: 25px;
                            color: $secondary_color;
                            border-radius: $border_radius;
                        }

                        .title {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            p {
                                color: $gray_color;
                            }
                        }
                    }

                    .timeline {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        h5 {
                            font-weight: 500;
                        }

                        p {
                            font-weight: bold;
                        }
                    }

                    .department {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        h5 {
                            font-weight: 500;
                        }

                        p {
                            font-weight: bold;
                        }
                    }
                }

                .description {
                    display: flex;
                    flex-direction: column;
                    gap: $medium_gap;

                    h5 {
                        color: $gray_color;
                        font-size: 18px;
                        font-weight: 500;
                    }

                    p {
                        width: 70%;
                    }
                }

                button {
                    padding: 12px 40px;
                }

                .status-btn {
                    background-color: $secondary_color;
                    color: $white_color;
                }

                .assign-btn {
                    background-color: $light_orange;
                    color: $secondary_color;
                }
            }
        }

        .tabs {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .tasks {
                display: flex;
                align-items: center;
                gap: $medium_gap;

                .task-count {
                    background-color: $light_orange;
                    text-align: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    color: $secondary_color;
                }
            }

            .input-fields {
                padding-top: 10px;

                .input {
                    position: relative;

                    input {
                        padding-left: 15px;
                        padding-right: 60px;
                    }

                    .icon {
                        position: absolute;
                        left: 220px;
                        top: 11px;
                        color: $gray_color;
                    }
                }
            }

            .btns {
                display: flex;
                align-items: center;
                gap: 16px;

                button {
                    padding: 10px 24px;
                }

                .add-btn {
                    background-color: $secondary_color;
                    color: $white_color;
                }

                .recent-btn {
                    background: transparent;
                    color: #807f7f;
                    border: 1px solid $gray_color;
                }
            }
        }
    }

    .tasks-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $big_gap;

        .task {
            background-color: $white_color;
            padding: 24px;
            border-radius: $border_radius;

            .task-status {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .task-actions {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    button {
                        padding: 8px 12px;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }

                    .mark-complete {
                        background-color: #145b9e3b;
                        color: #145c9e;

                        &:hover {
                            background-color: #145c9e;
                            color: white;
                        }
                    }

                    .view-details {
                        background-color: #f87c4735;
                        color: #f87c47;

                        &:hover {
                            background-color: #f87c47;
                            color: white;
                        }
                    }
                }

                .status {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .status-name {
                        background-color: #eaf5ff;
                        display: flex;
                        align-items: center;
                        gap: calc($medium_gap / 2);
                        padding: 12px 16px;
                        border-radius: $border_radius;
                    }

                    .icon {
                        .open-task-details-btn {
                            background: transparent;
                        }
                    }

                    p {
                        color: $primary_color;
                        font-size: 14px;
                    }

                    .dot {
                        width: 10px;
                        height: 10px;
                        background-color: $primary_color;
                        border-radius: 50%;
                    }
                }

                .task-description {
                    color: $gray_color;
                    font-weight: 200;
                }

                .task-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $gray_color;

                    .col {
                        .assignees {
                            display: flex;

                            .assignee {
                                padding: 8px;
                                border-radius: 50%;

                                p {
                                    font-weight: bold;
                                }
                            }

                            .assignee:nth-child(odd) {
                                background-color: #f2f2f2;
                                color: #ffbb38;
                            }

                            .assignee:nth-child(even) {
                                background-color: #bfdcf6;
                                color: $primary_color;
                            }
                        }
                    }

                    p {
                        font-weight: 200;
                    }
                }
            }
        }
    }
}