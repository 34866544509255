main {
  min-height: 100vh;
}

.adminpanel {
  background-color: #F7F6F6;
}

.admin-container {
  padding-top: 1rem;
  display: flex;
  gap: 20px;
  padding-inline: 1rem;
  min-height: 100vh;
}
.admin-container .button {
  display: none;
}

.admin-first-row .button {
  display: none;
}
.admin-first-row .categories {
  background-color: #FFFFFF;
  min-width: 360px;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  gap: 10px;
  color: #696969;
  border-radius: 12px;
  margin-bottom: 20px;
  text-align: center;
}
.admin-first-row .categories span {
  width: 112px;
  padding: 14px 10px;
  cursor: pointer;
}
.admin-first-row .categories span.active-category {
  border-bottom: 3px solid #F87C47;
  color: #1c274c;
}
.admin-first-row .orders {
  max-height: 705px;
  overflow-y: auto;
}
.admin-first-row .orders .item {
  display: flex;
  min-width: 358px;
  height: 81px;
  border-radius: 12px;
  padding: 16px;
  gap: 10px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}
.admin-first-row .orders .item .order-description h3 {
  font-weight: 500;
  font-size: 18px;
}
.admin-first-row .orders .item .order-description p {
  color: #676767;
}
.admin-first-row .orders .item .price {
  font-weight: 500;
  font-size: 18px;
}

.second-row {
  padding-bottom: 2rem;
  display: flex;
  flex: 1;
}
.second-row .order-details-admin {
  background-color: #FFFFFF;
  min-width: 300px;
  flex: 1;
  width: 100%;
  height: 776px;
  position: relative;
  padding: 12px 24px 12px 24px;
  padding-left: 44px;
}
.second-row .order-details-admin h2 {
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  top: 29px;
  left: 44px;
}
.second-row .order-details-admin .info {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 77px;
  margin-bottom: 35px;
}
.second-row .order-details-admin .info .name,
.second-row .order-details-admin .info .department {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.second-row .order-details-admin .info .name p,
.second-row .order-details-admin .info .department p {
  color: #b5b5b5;
}
.second-row .order-details-admin .order-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.second-row .order-details-admin .order-info .order-item {
  background-color: #f6f6f6;
  max-width: 909px;
  width: 100%;
  height: 87px;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 94px;
}
.second-row .order-details-admin .order-info .order-item .order-name {
  display: flex;
  gap: 12px;
  align-items: center;
}
.second-row .order-details-admin .order-info .order-item .order-name img {
  border-radius: 8px;
  max-width: 52px;
  max-height: 52px;
}
.second-row .order-details-admin .order-info .order-item .order-name p {
  font-weight: 500;
}
.second-row .order-details-admin .order-info .order-item .order-quantity {
  display: flex;
  align-items: center;
  gap: 12px;
}
.second-row .order-details-admin .order-info .order-item .order-quantity p {
  color: #767676;
}
.second-row .order-details-admin .order-info .order-item .order-quantity span,
.second-row .order-details-admin .order-info .order-item .order-quantity p {
  font-weight: 500;
}
.second-row .order-details-admin .order-info .order-item .price {
  display: flex;
  align-items: center;
  gap: 2px;
}
.second-row .order-details-admin .order-info .order-item .price span,
.second-row .order-details-admin .order-info .order-item .price p {
  font-weight: 500;
}
.second-row .order-details-admin .order-info .order-item .price span {
  font-size: 14px;
}

.buttons {
  display: flex;
  gap: 16px;
  position: absolute;
  bottom: 20px;
  right: 34px;
}
.buttons .cancel,
.buttons .send {
  height: 44px;
  display: flex;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 54px;
  cursor: pointer;
}
.buttons .cancel {
  width: 183px;
  background-color: #E5E5E5;
  color: #767676;
  padding: 12px 54px;
}
.buttons .send {
  width: 193px;
  background-color: #F87C47;
  color: #FFFFFF;
  padding: 12px 34px;
}

.footer-div {
  height: 10vh;
  background-color: #FFFFFF;
  width: 100%;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 1055px) {
  .second-row .order-details-admin .order-info .order-item {
    gap: 50px;
  }
}
@media screen and (max-width: 655px) {
  .admin-details-page .admin-container .order-details-admin .order-info .order-item {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 968px) {
  .admin-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .admin-container .categories {
    justify-content: space-between;
  }
  .admin-container .admin-first-row {
    flex: 1;
  }
  .admin-container .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
  }
  .admin-container .button a {
    color: #FFFFFF;
    background-color: #1C274C;
    padding: 12px 54px;
    width: 283px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 44px;
  }
  .second-row {
    display: none;
  }
}
.admin-details-page {
  background-color: #F9F9F9;
}
.admin-details-page .admin-container {
  padding-bottom: 2rem;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.admin-details-page .admin-container .order-details-admin {
  background-color: #FFFFFF;
  min-width: 300px;
  flex: 1;
  width: 100%;
  min-height: 776px;
  position: relative;
  padding: 12px 24px 12px 24px;
  padding-left: 44px;
}
.admin-details-page .admin-container .order-details-admin h2 {
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  top: 29px;
  left: 44px;
}
.admin-details-page .admin-container .order-details-admin .info {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  margin-top: 77px;
  margin-bottom: 35px;
}
.admin-details-page .admin-container .order-details-admin .info .name,
.admin-details-page .admin-container .order-details-admin .info .department {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.admin-details-page .admin-container .order-details-admin .info .name p,
.admin-details-page .admin-container .order-details-admin .info .department p {
  color: #b5b5b5;
}
.admin-details-page .admin-container .order-details-admin .order-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item {
  background-color: #f6f6f6;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  gap: 94px;
  flex-wrap: wrap;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-name {
  display: flex;
  gap: 12px;
  align-items: center;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-name img {
  border-radius: 8px;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-name p {
  font-weight: 500;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-quantity {
  display: flex;
  align-items: center;
  gap: 12px;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-quantity p {
  color: #767676;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-quantity span,
.admin-details-page .admin-container .order-details-admin .order-info .order-item .order-quantity p {
  font-weight: 500;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .price {
  display: flex;
  align-items: center;
  gap: 2px;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .price span,
.admin-details-page .admin-container .order-details-admin .order-info .order-item .price p {
  font-weight: 500;
}
.admin-details-page .admin-container .order-details-admin .order-info .order-item .price span {
  font-size: 14px;
}
.admin-details-page .buttons {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  right: 34px;
}
.admin-details-page .buttons .cancel,
.admin-details-page .buttons .send {
  height: 44px;
  display: flex;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 54px;
  cursor: pointer;
}
.admin-details-page .buttons .cancel {
  width: 183px;
  background-color: #E5E5E5;
  color: #767676;
  padding: 12px 54px;
}
.admin-details-page .buttons .send {
  width: 193px;
  background-color: #F87C47;
  color: #FFFFFF;
  padding: 12px 34px;
}
.admin-details-page .footer-div {
  height: 10vh;
  background-color: #FFFFFF;
  width: 100%;
  position: absolute;
  left: 0;
}/*# sourceMappingURL=adminPanel.css.map */