.category {
  display: flex;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  height: auto;
  max-width: 1032px;
  flex-wrap: wrap;
  flex: 1;
  padding: 12px 24px 12px 24px;
  gap: 24px;
}
.category .category-item {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  width: 122px;
  height: 76px;
}
.category .category-item.active {
  background-color: #F8F8FF;
  padding: 12px 14px;
  width: 146px;
  height: 76px;
  border-radius: 24px;
}
.category .category-item.active .image img {
  height: 52px;
}
.category .category-item.active a {
  color: #282C7D;
  font-weight: 500;
}
.category .category-item .image {
  width: 52px;
  height: 52px;
}
.category .category-item .image img {
  border-radius: 12px;
  max-width: 100%;
  max-height: 52px;
}
.category .category-item a {
  color: #888A8B;
}/*# sourceMappingURL=category.css.map */