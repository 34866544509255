$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.employees-section {
  .popup {
    place-content: center;

    .add-employee {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;

      .title {
        display: flex;
        justify-content: space-between;

        button {
          background: transparent;
          font-size: 24px;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        label {
          font-size: 14px;
        }

        .input-fields {
          .field {
            input,
            select {
              width: 200px;
              outline: none;
            }
          }
        }

        .radio-inputs {
          display: flex;
          gap: $big_gap;

          .radio-input-field {
            display: flex;
            gap: 6px;
          }
        }

        .btns {
          display: flex;
          gap: 24px;
          justify-content: right;

          button {
            padding: 16px;
          }

          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $gray_color;
          }

          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }

    .add-leave {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0px;
        button {
          background: transparent;
          font-size: 24px;
          padding: 10px;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;
        width: 100%;
        .input-fields {
          .field {
            input,
            select {
              width: 200px;
              outline: none;
              color: $gray_color;
            }
          }
        }
        select,
        .comments-section textarea {
          width: 100%;
          outline: none;
          font-size: 14px;
          color: $gray_color;
        }
        .date-input-fields {
          display: flex;
          justify-content: space-between;
          .date-input {
            width: 48%;
            &:first-child::before {
              content: "Start Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            &:last-child::before {
              content: "End Date";
              display: block;
              padding-bottom: 4px;
              font-size: 12px;
              color: $gray_color;
            }
            input {
              width: 100%;
              color: $gray_color;
              outline: none;
            }
          }
        }
        .comments-section textarea {
          width: 100%;
          resize: none;
          color: $gray_color;
        }
        .btns {
          display: flex;
          width: 100%;
          gap: 20px;
          button {
            padding: 16px;
            width: 100%;
          }
          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $gray_color;
          }
          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }
  }

  .underline {
    background-color: #f1f1f1;
    width: 100%;
    height: 1px;
  }

  .contents-container {
    .title-contents {
      .top {
        .col-title {
          p {
            color: $gray_color;
          }

          button {
            background-color: $secondary_color;
            padding: 10px 24px;
            color: $white_color;
          }
        }
      }

      .input-fields {
        padding-top: 10px;

        .input {
          position: relative;

          input {
            padding-left: 15px;
            padding-right: 60px;
            outline: none;
          }
          .icon {
            position: absolute;
            left: 220px;
            top: 15px;
            color: $gray_color;
          }
        }
      }
    }
  }
}

.col-2 {
  width: 100%;
}

// responsiveness
@media screen and (max-width: 810px) {
  .employees-section {
    .popup {
      .add-employee {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .employees-section {
    .popup {
      .add-employee {
        width: 100%;
      }
    }
    .row {
      .contents-container {
        .title-contents {
          .top {
            .input-fields {
              .input {
                input {
                  width: 100%;
                  outline: none;
                }
              }
            }

            .col-title {
              button {
                width: 240px;
              }
            }
          }
        }
      }
    }
  }
}
