.cohort-details-tabs{
 
  .cohort-tab-buttons{
    display: flex;
    background-color: white;
    padding-inline: 30px;
    padding-block: 25px;
    border-radius: 12px;
    gap: 50px;
    align-items: center;
    flex-wrap: wrap;
   

    .tab-button{
      display: flex;
      align-items: center;
      gap: 10px;
      color: #8A8A8A;
      cursor: pointer;

      &.active{
        background-color: #FEEEE7;
        color: #F87C47 !important;
        padding: 16px;
        border-radius: 12px;
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .cohort-details-tabs{
    .cohort-tab-buttons{
      gap: 25px;
    }
  }
}