.transaction-table-contents{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.titles{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width:200px;
    width: 100%;
    height: 25px;
    // background-color: #e0dddd;

    .divider{
        width: 6px;
        height: 20px;
        background-color: #797777;
    }
    
    .title-text{
        max-width:95px;
        width: 100%;
        height: 20px;
        background-color: #ffffff;
    }
}

.transaction-filtering{
    // max-width:1376px ;
    width: 100%;
    height: 80px;
    background-color: #FFFF;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
   
   

    .filtering-contents{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        gap: 10px;
       
       

        .transactions{
            max-width:132px ;
            width: 100%;
            height: 48px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            // background-color: #e0dddd;
            padding: 5px;
            .transaction-title{
                max-width: 103px;
                width: 100%;
                height: 24px;
                background-color: #F8F8F8;
            }

            .tansaction-numbers{
                max-width:132px ;
                height: 20px;
                background-color: #F8F8F8;
            }
        }

        .search-transactions{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            gap: 10px;
            max-width:494px;
            width: 100%;
            height: 48px;
            background-color: #F8F8F8;
            border-radius: 8px;
            .search-placeholder{
                max-width: 300px;
                width: 100%;
                height: 24px;
                background-color: #FFFFFF;
            }

            .search-icon{
                max-width: 20px;
                width: 100%;
                height: 20px;
                background-color:#FFFFFF;
            }
        }

        .transaction-filter-button{
            display: flex;
            align-items: center;
            gap: 5px;
            max-width: 131px;
            width: 100%;
            height: 44px;
            background-color: #F8F8F8;
            padding: 15px;
            border-radius: 8px;

            .filter-text{
                max-width: 90px;
                width: 100%;
                height: 24px;
                background-color: #FFFFFF;
            }

            .filter-icon{
                max-width: 30px;
                width: 100%;
                height: 24px;
                background-color:#FFFFFF;
            }

           

        }
    }

}


.table-contents{
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .table{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        // background-color: #FCFBFB;

        .contents{
            width: 100%;
            height: 68px;
            background-color: #FFFFFF;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            padding: 24px 12px;

            .content-detail{
                max-width: 200px;
                width: 100%;
                height: 24px;
                background-color: #F8F8F8;
                border-radius: 5px;
            }

            .white{
                background-color: #FFFFFF;
            }
        }

        .gray{
            background-color: #F8F8F8;
        }
    }

    .table-pagination{
        display: flex;
        align-items: center;
        gap: 10px;
        max-width:315px ;
        width: 100%;
        height: 45px;
        padding: 0 16px;
        background-color: #F8F8F8;
        align-self: flex-end;
        border-radius:8px;

        .pagination{
            width: 100%;
            max-width: 70px;
            height: 30px;
            background-color: #FFFFFF;
            border-radius: 5px;
           
        }
    }
}