.button {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  background: #F6FBFF;
  border: transparent;
  border-radius: 8px;
  padding: 14px;
  font-family: inherit;
  display: flex;
  align-items: center;
  color: #145C9E;
  gap: 12px;
}

.number {
  background-color: #F9F9F9;
  color: #000000;
}

.cohorts-archive {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.cohorts-archive .cohort-card {
  flex: 1 1 444px;
  width: 100%;
  padding: 12px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cohorts-archive .cohort-card h4 {
  color: #000000;
}
.cohorts-archive .cohort-card .cohort-name-date-enrol_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.cohorts-archive .cohort-card .cohort-name-date-enrol_btn .cohort-name-dates {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cohorts-archive .cohort-card .cohort-name-date-enrol_btn .cohort-name-dates .cohort-dates {
  display: flex;
  gap: 12px;
  color: #817A7A;
}
.cohorts-archive .cohort-card .cohort-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}/*# sourceMappingURL=cohorts_cards.css.map */