.button {
    width: fit-content;
    cursor: pointer;
    background: #F6FBFF;
    border: transparent;
    border-radius: 8px;
    padding: 14px;
    font-family: inherit;
    display: flex;
    align-items: center;
    color: #145C9E;
    gap: 12px;
    // justify-content: center;
}

.number{
    background-color: #F9F9F9;
    color: #000000;
}

.cohorts-archive{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .cohort-card{
        flex: 1 1 444px;
        // max-width: 444px;
        width: 100%;
        // height: fit-content;
        padding: 12px;
        background-color: #FFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    
        h4{
            color: #000000;
        }
    
        .cohort-name-date-enrol_btn{
            display: flex;
            // flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
    
            .cohort-name-dates{
                display: flex;
                flex-direction: column;
                gap: 12px;
                .cohort-dates{
                    display: flex;
                    gap: 12px;
                    color: #817A7A;
                }
            }
        }
    
        .cohort-numbers{
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
        }
    }
}

