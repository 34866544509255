@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Outfit", sans-serif;
    background-color: $light_gray;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

button {
    cursor: pointer;
    border: transparent;
    border-radius: 8px;
    padding: 14px;
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
}



input {
    padding: 11px 26px;
    border: 1px solid #ececec;
    border-radius: 8px;
}

::placeholder {
    color: $gray_color;
    font-family: "Outfit", sans-serif;
}

select {
    padding: 11px 26px;
    border: 1px solid #ececec;
    border-radius: 8px;
    background: transparent;

}

::placeholder {
    color: $gray_color;
    font-family: "Outfit", sans-serif;

}

textarea {
    padding: 11px 14px;
    color: $gray_color;
    border-radius: 8px;
    border: 1px solid #ececec;

}

::placeholder {
    color: $gray_color;
    font-family: "Outfit", sans-serif;

}

// no data message
.no-data {
    color: $primary_color;
    font-size: 20px;
    padding-top: 20px;
}

.message {
    color: $secondary_color;
    font-size: 18px;
}

.primary-button {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    background-color: $secondary_color;
    color: white;
    transition: linear 0.3 linear;

    &:hover {
        background-color: $primary_color;
    }
}

.secondary-button {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    background-color: white;
    color: $primary_color;
    transition: linear 0.3 linear;

    border: 1px solid $primary_color;

    &:hover {
        background-color: $primary_color;
        color: white;
    }
}

.loading-container {
    color: $primary_color;
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {
        font-size: 24px;
    }

    @keyframes loadingAnimation {
        0% {
            opacity: 0;
        }

        25% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        75% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    .loading-dots {
        display: inline-block;
        font-size: 24px;
    }

    .loading-dot {
        display: inline-block;
        animation: loadingAnimation 1s infinite;
    }

    .loading-dot:nth-child(1) {
        animation-delay: 0.1s;
    }

    .loading-dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .loading-dot:nth-child(3) {
        animation-delay: 0.3s;
    }
}

.input-fields {
    display: flex;
    align-items: center;
    gap: $big_gap;

    .field {
        display: flex;
        flex-direction: column;
        gap: $medium_gap;
    }
}

// sidebar
.sidebar {
    position: fixed;
    height: 100vh;
    height: 100svh;
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    width: 20%;
    background-color: $primary_color;
    left: 0;
    top: 0;

    .links-container {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        .branding {
            text-align: center;
        }

        .links {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .link {
                color: $white_color;
                display: flex;
                align-items: center;
                gap: $medium_gap;

                a {
                    color: $white_color;
                    font-size: 14px;
                }
            }

            .active {
                background-color: $secondary_color;
                padding: 12px;
                border-radius: $border_radius;
            }
        }
    }

    .logout {
        color: $white_color;
        padding-top: 450px;
        padding-inline: 30px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $medium_gap;
            border: transparent;
            background: transparent;
            font-size: 16px;
            color: $white_color;
        }
    }
}

//mobile sidebar
.mobile-sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    background-color: $primary_color;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 20;

    .links-container {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        .links {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .link {
                color: $white_color;
                display: flex;
                align-items: center;
                gap: $medium_gap;

                a {
                    color: $white_color;
                    font-size: 14px;
                }
            }

            .active {
                background-color: $secondary_color;
                padding: 12px;
                border-radius: $border_radius;
                width: 100%;
            }
        }
    }

    .logout {
        color: $white_color;
        padding-top: 450px;
        padding-inline: 30px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $medium_gap;
            border: transparent;
            background: transparent;
            font-size: 16px;
            color: $white_color;
        }
    }
}

.col-2 {
    width: 80%;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;

    .user-details-popup {
        background-color: rgba(0, 0, 0, 0.25);
        position: fixed;
        display: grid;
        justify-content: right;
        z-index: 30;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-inline: 24px;
        padding-top: 60px;

        .user-profile-details {
            background-color: $white_color;
            height: 45%;
            padding: 24px;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .user-row {
                display: flex;
                gap: 8px;
                background-color: #f9f9f9;
                padding: 12px;
                border-radius: 16px;

                .col {
                    .email {
                        color: #5e5d5d;
                    }
                }

                .col-1 {
                    background-color: $light_orange;
                    border-radius: 50%;
                    padding: 15px;

                    p {
                        color: $secondary_color;
                        font-weight: bold;
                    }
                }
            }

            .popup-links-container {
                padding-top: 18px;
                display: flex;
                flex-direction: column;
                gap: $big_gap;

                .popup-link {
                    display: flex;
                    gap: 6px;
                    align-items: center;

                    img {
                        width: 24px;
                    }

                    button {
                        background: transparent;
                        font-size: 16px;

                        a {
                            color: #000;
                        }
                    }

                    .signout {
                        a {
                            color: $secondary_color;
                        }
                    }
                }
            }
        }
    }

    h3 {
        text-transform: capitalize;
    }

    .menu-btn {
        display: none;
        background: transparent;
        font-size: 24px;
        color: #aeaeae;
    }

    .input {
        position: relative;

        .icon {
            position: absolute;
            top: 15px;
            left: 8px;
            font-size: 12px;
            color: $gray_color;
        }
    }

    .logo {
        display: none;
    }

    .profile-section {
        display: flex;
        align-items: center;
        gap: $medium_gap;

        .notification {
            border: 1px solid #ececec;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            text-align: center;
            color: $gray_color;
            font-size: 30px;
        }

        .profile {
            background-color: $light_orange;
            padding: 15px;
            border-radius: 50%;

            p {
                font-weight: bold;
                color: $secondary_color;
            }
        }

        .user-details {
            button {
                background: transparent;
                font-size: 30px;
            }
        }
    }
}

.contents-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.table-contents {
    table {
        width: 100%;
        border-collapse: collapse;

        .mobile-layout {
            display: none;
        }

        thead {
            background-color: #fafafa;

        }

        th,
        td {
            text-align: left;
            padding: 16px;
        }

        th {
            font-weight: 200;
            color: #a5a3a9;
        }

        td {
            color: #666666;
            font-weight: 300;
            font-size: 14px;

            span {
                background-color: $light_orange;
                color: $secondary_color;
                font-weight: bold;
                padding: 8px;
                border-radius: 50%;
            }

            button {
                background: transparent;
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 15px 30px;

    button {
        background: transparent;
        border: 0.5px solid #a5a3a9;
        padding: 8px 18px;
        border-radius: 0;
        color: #a5a3a9;
    }

    .active {
        background-color: $primary_color;
    }
}

.popup {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-items: center;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

// responsiveness
@media screen and (max-width: 1024px) {
    .sidebar {
        display: none;
    }

    .col-2 {
        width: 100%;
    }

    nav {
        .menu-btn {
            display: block;
        }
    }
}

@media screen and (max-width: 540px) {
    nav {
        padding: 10px;

        h3 {
            display: none;
        }

        .input {
            display: none;
        }

        .logo {
            display: block;
        }
    }

    .contents-container {
        margin: 15px 0;

        .top {
            flex-direction: column;
            gap: $big_gap;
            align-items: start;
        }
    }

    .table-contents {
        table {
            th {
                display: none;
            }

            td {
                text-align: left;
                padding: 24px 10px;
                font-size: 12px;
            }

            .hidden-table-col {
                display: none;
            }

            .mobile-layout {
                display: flex;
                flex-direction: column;

                .user-details {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    padding-right: 12px;

                    .email {
                        font-size: 12px;
                    }
                }
            }
        }

        .pagination {
            .pagination-btn {
                display: none;
            }
        }
    }

    .popup {
        padding-inline: 12px;
    }

    .input-fields {
        flex-direction: column;

        .field {
            display: flex;
            flex-direction: column;
            gap: $medium_gap;
        }
    }
}

// custom main input

.custom-select-input {
    position: relative;

    .options-container {
        background-color: whitesmoke;
        width: 100%;
        padding: 12px;
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 4;
    }

    .options {
        display: grid;
        height: fit-content;

        .option {
            padding: 6px;
            border-bottom: 1px solid rgb(231, 231, 231);

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: $secondary-color;
                border-color: transparent;
            }

            &:first-child {
                color: gray;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

.tabs {
    display: flex;
    align-items: center;
    gap: 12px;

    .tab {
        display: flex;
        align-content: center;
        gap: 6px;
    }
}

.loader {
    background-color: whitesmoke;
    animation: shimmer 2s ease-in-out infinite;
    background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
    background-size: 800px 100px;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.no-results-found {
    padding: 24px;
    background-color: whitesmoke;
    margin-top: 12px;
    width: 100%;
    display: grid;
    height: fit-content;
    gap: 12px;
    border-radius: 12px;

    button {
        width: fit-content;
        align-items: center;
    }
}


.notification {
    position: fixed;
    top: 20px;
    /* Changed from bottom to top for header positioning */
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s;
}

.notification.visible {
    opacity: 1;
    transform: translateY(0);
}

.search-field {
    position: relative;

    .search-container {
        position: absolute;
        top: 68px;
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
        background-color: white;
        border: 1px solid #ccc;
        z-index: 10;
        padding: 12px;
        border-radius: 12px;

        display: grid;
        gap: 12px;

        .search-input {
            width: 100%;
            position: sticky;
            top: 0;

            input {
                width: 100%;
            }
        }

        .result {
            color: gray;
            padding: 12px;
            border-bottom: 1px solid whitesmoke;
        }
    }
}

.loading-requirements {
    padding: 24px;
    background-color: #f87c473f;
    text-align: center;
    display: grid;
    height: fit-content;
    gap: 12px;
    justify-self: center;
    justify-items: center;
}


.loading-icon {
    animation: spin 01.2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-items {
    display: flex;
    align-items: center;
    gap: 12px;
}