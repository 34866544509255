$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

$big_gap: 24px;

.not-found {
    display: grid;
    place-items: center;
    height: 80vh;

    .container {
        display: flex;
        flex-direction: column;
        gap: $big_gap;
        text-align: center;

        h2 {
            font-size: 40px;
            color: $primary_color;
        }

        button {
            background-color: $secondary_color;
            color: $white_color;
            padding: 16px 24px;
            font-size: 18px;
        }
    }
}