.chorts-page-contents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.titles {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 200px;
  width: 100%;
  height: 25px;
}
.titles .divider {
  width: 6px;
  height: 20px;
  background-color: #797777;
}
.titles .title-text {
  max-width: 95px;
  width: 100%;
  height: 20px;
  background-color: #ffffff;
}

.transaction-filtering {
  width: 100%;
  height: 80px;
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transaction-filtering .filtering-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  gap: 10px;
}
.transaction-filtering .filtering-contents .transactions {
  max-width: 132px;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}
.transaction-filtering .filtering-contents .transactions .transaction-title {
  max-width: 103px;
  width: 100%;
  height: 24px;
  background-color: #F8F8F8;
}
.transaction-filtering .filtering-contents .transactions .tansaction-numbers {
  max-width: 132px;
  height: 20px;
  background-color: #F8F8F8;
}
.transaction-filtering .filtering-contents .search-transactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 10px;
  max-width: 494px;
  width: 100%;
  height: 48px;
  background-color: #F8F8F8;
  border-radius: 8px;
}
.transaction-filtering .filtering-contents .search-transactions .search-placeholder {
  max-width: 300px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .search-transactions .search-icon {
  max-width: 20px;
  width: 100%;
  height: 20px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .transaction-filter-button {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 131px;
  width: 100%;
  height: 44px;
  background-color: #F8F8F8;
  padding: 15px;
  border-radius: 8px;
}
.transaction-filtering .filtering-contents .transaction-filter-button .filter-text {
  max-width: 90px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .transaction-filter-button .filter-icon {
  max-width: 30px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}

.cohorts-contents {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cohorts-contents .cohorts-cards {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}
.cohorts-contents .cohorts-cards .contents-cards {
  max-width: 545px;
  width: 100%;
  height: 148px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cohorts-contents .cohorts-cards .contents-cards .card-text-btn {
  width: 100%;
  display: flex;
  gap: 10px;
}
.cohorts-contents .cohorts-cards .contents-cards .card-text-btn .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cohorts-contents .cohorts-cards .contents-cards .card-text-btn .text .card-title {
  max-width: 210px;
  width: 100%;
  height: 20px;
  background-color: #F8F8F8;
}
.cohorts-contents .cohorts-cards .contents-cards .card-text-btn .text .card-date {
  max-width: 217px;
  width: 100%;
  height: 18px;
  background-color: #F8F8F8;
}
.cohorts-contents .cohorts-cards .contents-cards .card-text-btn .enrol-btn {
  max-width: 149px;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background-color: #F8F8F8;
}
.cohorts-contents .cohorts-cards .contents-cards .btns-nombers {
  display: flex;
  width: 100%;
  gap: 12px;
}
.cohorts-contents .cohorts-cards .contents-cards .btns-nombers .btn-number {
  max-width: 111px;
  width: 100%;
  height: 34px;
  background-color: #F8F8F8;
  border-radius: 5px;
  padding: 8px 16px;
}/*# sourceMappingURL=cohorts_page_loader.css.map */