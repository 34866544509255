.employees-section .popup {
  place-content: center;
}
.employees-section .popup .add-employee {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-employee .title {
  display: flex;
  justify-content: space-between;
}
.employees-section .popup .add-employee .title button {
  background: transparent;
  font-size: 24px;
}
.employees-section .popup .add-employee form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-employee form label {
  font-size: 14px;
}
.employees-section .popup .add-employee form .input-fields .field input,
.employees-section .popup .add-employee form .input-fields .field select {
  width: 200px;
  outline: none;
}
.employees-section .popup .add-employee form .radio-inputs {
  display: flex;
  gap: 24px;
}
.employees-section .popup .add-employee form .radio-inputs .radio-input-field {
  display: flex;
  gap: 6px;
}
.employees-section .popup .add-employee form .btns {
  display: flex;
  gap: 24px;
  justify-content: right;
}
.employees-section .popup .add-employee form .btns button {
  padding: 16px;
}
.employees-section .popup .add-employee form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.employees-section .popup .add-employee form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.employees-section .popup .add-leave {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-leave .title {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
.employees-section .popup .add-leave .title button {
  background: transparent;
  font-size: 24px;
  padding: 10px;
}
.employees-section .popup .add-leave form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.employees-section .popup .add-leave form .input-fields .field input,
.employees-section .popup .add-leave form .input-fields .field select {
  width: 200px;
  outline: none;
  color: #979797;
}
.employees-section .popup .add-leave form select,
.employees-section .popup .add-leave form .comments-section textarea {
  width: 100%;
  outline: none;
  font-size: 14px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields {
  display: flex;
  justify-content: space-between;
}
.employees-section .popup .add-leave form .date-input-fields .date-input {
  width: 48%;
}
.employees-section .popup .add-leave form .date-input-fields .date-input:first-child::before {
  content: "Start Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields .date-input:last-child::before {
  content: "End Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields .date-input input {
  width: 100%;
  color: #979797;
  outline: none;
}
.employees-section .popup .add-leave form .comments-section textarea {
  width: 100%;
  resize: none;
  color: #979797;
}
.employees-section .popup .add-leave form .btns {
  display: flex;
  width: 100%;
  gap: 20px;
}
.employees-section .popup .add-leave form .btns button {
  padding: 16px;
  width: 100%;
}
.employees-section .popup .add-leave form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.employees-section .popup .add-leave form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.employees-section .popup .performance {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .performance .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 12px;
}
.employees-section .popup .performance .title h3 {
  color: #145c9e;
  font-size: 26px;
  font-weight: 600;
}
.employees-section .popup .performance .title p {
  color: #979797;
  font-size: 14px;
}
.employees-section .popup .performance .title button {
  background: transparent;
  font-size: 24px;
  padding: 10px;
  align-self: baseline;
}
.employees-section .popup .performance .title2 {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 12px;
}
.employees-section .popup .performance .title2 h3 {
  color: black;
  font-size: 22px;
  font-weight: 600;
}
.employees-section .popup .performance .title2 p {
  color: #979797;
  font-size: 14px;
  padding-top: 6px;
}
.employees-section .popup .performance .title2 button {
  background: transparent;
  font-size: 24px;
  padding: 10px;
  align-self: baseline;
}
.employees-section .popup .performance .progress-container {
  height: 10px;
  background-color: grey;
  border-radius: 5px;
}
.employees-section .popup .performance .progress-container .progress-bar {
  height: 100%;
  background-color: #f87c47;
  border-radius: 5px;
  transition: width 0.3s ease;
}
.employees-section .popup .performance form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.employees-section .popup .performance form .form-step {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .performance form .input-fields {
  padding-top: 10px;
}
.employees-section .popup .performance form .input-fields .field input,
.employees-section .popup .performance form .input-fields .field select {
  outline: none;
  color: #979797;
}
.employees-section .popup .performance form .input-fields .input {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.employees-section .popup .performance form .input-fields .input label {
  font-size: 14px;
  font-weight: 500;
}
.employees-section .popup .performance form .input-fields .input input {
  padding-left: 40px;
  padding-right: 0px;
  outline: none;
}
.employees-section .popup .performance form .input-fields .input .icon {
  position: absolute;
  left: 15px;
  top: 44px;
  color: #979797;
}
.employees-section .popup .performance form .selectOption {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.employees-section .popup .performance form .selectOption .title2 {
  display: block;
}
.employees-section .popup .performance form .selectOption .title2 h3 {
  font-size: 18px;
  font-weight: 600;
}
.employees-section .popup .performance form .selectOption .selectOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 10px;
}
.employees-section .popup .performance form .selectOption option {
  background-color: #f9f9f9;
  padding: 12px 24px;
}
.employees-section .popup .performance form .employee-performance {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 12px;
}
.employees-section .popup .performance form .employee-performance .title {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  background-color: #fafafa;
}
.employees-section .popup .performance form .employee-performance .title span {
  background-color: #fff7f3;
  color: #f87c47;
  font-weight: bold;
  padding: 8px;
  border-radius: 50%;
}
.employees-section .popup .performance form .employee-performance .title div {
  display: block;
  position: relative;
  left: 0;
}
.employees-section .popup .performance form .employee-performance .title div p {
  color: black;
  font-weight: 400;
  font-size: 14px;
}
.employees-section .popup .performance form .employee-performance .title div small {
  color: #666666;
  font-weight: 300;
  font-size: 12px;
}
.employees-section .popup .performance form .employee-performance .ratings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-stars {
  display: block;
  background-color: #fff5f1;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 12px;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-stars p {
  color: black;
  font-weight: 500;
  font-size: 16px;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-stars .star {
  font-size: 20px;
  color: #545352;
  font-family: "Outfit", sans-serif;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-stars .starfull {
  font-size: 20px;
  color: #f87c47;
  font-family: "Outfit", sans-serif;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-bttn {
  display: flex;
  flex-direction: row;
  background-color: #fff5f1;
  padding: 18px 24px;
  gap: 10px;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-bttn p {
  background-color: #fff7f3;
  color: #f87c47;
  font-weight: bold;
  padding: 8px;
  border-radius: 50%;
}
.employees-section .popup .performance form .employee-performance .ratings .ratings-bttn h5 {
  color: #f87c47;
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  padding-top: 10px;
}
.employees-section .popup .performance form .employee-performance .checkIf {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.employees-section .popup .performance form .employee-performance .checkIf label {
  color: black;
  font-weight: 500;
  font-size: 16px;
}
.employees-section .popup .performance form .employee-performance .checkIf .ratings-bttn {
  background-color: #f7f7f7;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
}
.employees-section .popup .performance form .employee-performance .checkIf .ratings-bttn code {
  background-color: white;
  height: 20px;
  width: 20px;
}
.employees-section .popup .performance form .employee-performance .checkIf .ratings-bttn p {
  color: #545352;
  font-weight: 400;
  font-size: 14px;
  background-color: #f7f7f7;
  padding: 0px;
}
.employees-section .popup .performance form .employee-performance .checkIf .selected {
  cursor: pointer;
  background-color: #f0f0f0;
}
.employees-section .popup .performance form .employee-performance .checkIf .selected code {
  background-color: #fff5f1;
  border-radius: 10px;
}
.employees-section .popup .performance form .employee-performance .checkIf .selected p {
  color: #414040;
}
.employees-section .popup .performance form .comments-section label {
  font-size: 14px;
  font-weight: 500;
}
.employees-section .popup .performance form select,
.employees-section .popup .performance form .comments-section textarea {
  width: 100%;
  outline: none;
  font-size: 14px;
  color: #979797;
}
.employees-section .popup .performance form .date-input-fields {
  display: flex;
  justify-content: space-between;
}
.employees-section .popup .performance form .date-input-fields .date-input {
  width: 48%;
}
.employees-section .popup .performance form .date-input-fields .date-input:first-child::before {
  content: "Start Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .performance form .date-input-fields .date-input:last-child::before {
  content: "End Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .performance form .date-input-fields .date-input input {
  width: 100%;
  color: #979797;
  outline: none;
}
.employees-section .popup .performance form .comments-section textarea {
  width: 100%;
  resize: none;
  color: #979797;
}
.employees-section .popup .performance form .table-contents {
  padding: 0%;
}
.employees-section .popup .performance form .btns {
  display: flex;
  width: 100%;
  gap: 20px;
}
.employees-section .popup .performance form .btns button {
  padding: 16px;
  width: 100%;
}
.employees-section .popup .performance form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #f87c47;
}
.employees-section .popup .performance form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.employees-section .underline {
  background-color: #f1f1f1;
  width: 100%;
  height: 1px;
}
.employees-section .contents-container .title-contents .top .col-title p {
  color: #979797;
}
.employees-section .contents-container .title-contents .top .col-title button {
  background-color: #f87c47;
  padding: 10px 24px;
  color: #ffffff;
}
.employees-section .contents-container .title-contents .input-fields {
  padding-top: 10px;
}
.employees-section .contents-container .title-contents .input-fields .input {
  position: relative;
}
.employees-section .contents-container .title-contents .input-fields .input input {
  padding-left: 15px;
  padding-right: 60px;
  outline: none;
}
.employees-section .contents-container .title-contents .input-fields .input .icon {
  position: absolute;
  left: 220px;
  top: 15px;
  color: #979797;
}

.col-2 {
  width: 100%;
}

@media screen and (max-width: 810px) {
  .employees-section .popup .add-employee {
    width: 80%;
  }
}
@media screen and (max-width: 540px) {
  .employees-section .popup .add-employee {
    width: 100%;
  }
  .employees-section .row .contents-container .title-contents .top .input-fields .input input {
    width: 100%;
    outline: none;
  }
  .employees-section .row .contents-container .title-contents .top .col-title button {
    width: 240px;
  }
}/*# sourceMappingURL=employees.css.map */