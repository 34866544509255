.employees-section .popup {
  place-content: center;
}
.employees-section .popup .add-employee {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-employee .title {
  display: flex;
  justify-content: space-between;
}
.employees-section .popup .add-employee .title button {
  background: transparent;
  font-size: 24px;
}
.employees-section .popup .add-employee form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-employee form label {
  font-size: 14px;
}
.employees-section .popup .add-employee form .input-fields .field input,
.employees-section .popup .add-employee form .input-fields .field select {
  width: 200px;
  outline: none;
}
.employees-section .popup .add-employee form .radio-inputs {
  display: flex;
  gap: 24px;
}
.employees-section .popup .add-employee form .radio-inputs .radio-input-field {
  display: flex;
  gap: 6px;
}
.employees-section .popup .add-employee form .btns {
  display: flex;
  gap: 24px;
  justify-content: right;
}
.employees-section .popup .add-employee form .btns button {
  padding: 16px;
}
.employees-section .popup .add-employee form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.employees-section .popup .add-employee form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.employees-section .popup .add-leave {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.employees-section .popup .add-leave .title {
  display: flex;
  justify-content: space-between;
  padding: 0px;
}
.employees-section .popup .add-leave .title button {
  background: transparent;
  font-size: 24px;
  padding: 10px;
}
.employees-section .popup .add-leave form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.employees-section .popup .add-leave form .input-fields .field input,
.employees-section .popup .add-leave form .input-fields .field select {
  width: 200px;
  outline: none;
  color: #979797;
}
.employees-section .popup .add-leave form select,
.employees-section .popup .add-leave form .comments-section textarea {
  width: 100%;
  outline: none;
  font-size: 14px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields {
  display: flex;
  justify-content: space-between;
}
.employees-section .popup .add-leave form .date-input-fields .date-input {
  width: 48%;
}
.employees-section .popup .add-leave form .date-input-fields .date-input:first-child::before {
  content: "Start Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields .date-input:last-child::before {
  content: "End Date";
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  color: #979797;
}
.employees-section .popup .add-leave form .date-input-fields .date-input input {
  width: 100%;
  color: #979797;
  outline: none;
}
.employees-section .popup .add-leave form .comments-section textarea {
  width: 100%;
  resize: none;
  color: #979797;
}
.employees-section .popup .add-leave form .btns {
  display: flex;
  width: 100%;
  gap: 20px;
}
.employees-section .popup .add-leave form .btns button {
  padding: 16px;
  width: 100%;
}
.employees-section .popup .add-leave form .btns .cancel-btn {
  border: 1px solid #ebebeb;
  background: transparent;
  color: #979797;
}
.employees-section .popup .add-leave form .btns .add-btn {
  background-color: #f87c47;
  color: #ffffff;
}
.employees-section .underline {
  background-color: #f1f1f1;
  width: 100%;
  height: 1px;
}
.employees-section .contents-container .title-contents .top .col-title p {
  color: #979797;
}
.employees-section .contents-container .title-contents .top .col-title button {
  background-color: #f87c47;
  padding: 10px 24px;
  color: #ffffff;
}
.employees-section .contents-container .title-contents .input-fields {
  padding-top: 10px;
}
.employees-section .contents-container .title-contents .input-fields .input {
  position: relative;
}
.employees-section .contents-container .title-contents .input-fields .input input {
  padding-left: 15px;
  padding-right: 60px;
  outline: none;
}
.employees-section .contents-container .title-contents .input-fields .input .icon {
  position: absolute;
  left: 220px;
  top: 15px;
  color: #979797;
}

.col-2 {
  width: 100%;
}

@media screen and (max-width: 810px) {
  .employees-section .popup .add-employee {
    width: 80%;
  }
}
@media screen and (max-width: 540px) {
  .employees-section .popup .add-employee {
    width: 100%;
  }
  .employees-section .row .contents-container .title-contents .top .input-fields .input input {
    width: 100%;
    outline: none;
  }
  .employees-section .row .contents-container .title-contents .top .col-title button {
    width: 240px;
  }
}/*# sourceMappingURL=leaves.css.map */