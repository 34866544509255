@import '../variables';

.recent-blogs-page {
    .recent-blogs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        .blog-card {

            img {
                width: 150px;
                border-radius: 0.5rem;
                height: 100%;
                object-fit: cover;
            }

            display: flex;
            align-items: stretch;
            gap: 1rem;
            background-color: white;
            padding: 1rem;
            border-radius: 0.5rem;
        }

        .blog-info {
            display: grid;
            height: fit-content;
            gap: 0.5rem;
        }

        .actions {
            display: flex;
            align-items: center;
            gap: 1rem;

            .action {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 12px;
                cursor: pointer;
            }

            .delete {
                color: orangered;
            }

            .edit {
                color: #145C9E;
            }

            .view {
                color: rgb(148, 148, 38);
            }
        }

        .authors-container {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .authors {
            display: flex;
            align-items: center;
            gap: 1rem;
            flex-wrap: wrap;

            >:nth-child(even) {
                background-color: lighten($color: $primary-color, $amount: 32);
                color: $primary-color;
            }

            .author,
            .author-name {
                p {
                    font-size: 3px;
                }
            }

            .author {
                height: 32px;
                width: 32px;
            }

            .author-name {
                background-color: lighten($color: #145C9E, $amount: 60);
                color: #145C9E;
                height: 32px;
                display: flex;
                align-items: center;
                padding: 0 1rem;
                border-radius: 0.5rem;

            }
        }
    }
}

.blog-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 12px;
    color: #666666;
}

.delete-blog-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-blog-form {
    display: grid;
    gap: 1rem;
    padding: 2rem;
    background-color: white;
    max-width: 600px;
    height: fit-content;
    border-radius: 0.5rem;

    .delete-buttons {
        display: flex;
        align-items: center;
        gap: 1rem;

        .delete {
            background-color: tomato;
            color: white;
        }
    }
}