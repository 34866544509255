$primary_color: #145C9E;
$border-primary-color: #1B63A6;
$nav-color: #A0CAF0;

// secondary-color:
$secondary_color: #F87C47;

.dashboard-side-bar {
    background-color: $primary_color;
    height: 100vh;
    overflow: auto;
    max-width: 276px;
    color: $nav-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    left: 0;
    top: 0;
    bottom: 0;

    a {
        color: inherit;
    }

    .top,
    .bottom {
        padding: 24px;
    }

    .close-icon {
        display: none;
    }

    .branding {
        height: 60px;
        display: flex;
        align-items: center;

        .site-title {
            font-size: 24px;
            color: #fff;

            span {
                color: $secondary_color
            }
        }
    }

    .overview {
        padding-bottom: 24px;
    }

    .overview,
    .settings {

        .menu-link,
        .title {
            padding: 10px 0;
            display: flex;
            align-items: center;
            gap: 12px;

            .icon {
                color: $nav-color;
            }
        }

        .active {
            padding: 10px 16px;
        }

    }

    .training-center,
    .job-match,
    .canteen {
        .title {
            justify-content: space-between;
        }
    }
}

.modules {
    display: grid;
    height: fit-content;

    .module {
        display: grid;
        gap: 16px;
        height: fit-content;

        .links {
            display: grid;
            gap: 12px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
        }

        .menu-link {
            cursor: pointer;
            display: flex;
            gap: 12px;
            align-items: center;
        }

        .open {
            max-height: 500px;
            padding-bottom: 24px;
        }

        .submenu-open {
            .icon {
                animation: subMenuOpen 0.3s linear forwards;
            }

            @keyframes subMenuOpen {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(90deg);
                }
            }
        }
    }
}



.text {
    font-size: 16px;
    font-weight: normal;
}

.links {
    .menu-link {
        gap: 6px;
    }

    text {
        font-size: 12px;
    }
}

.divider {
    color: $border-primary-color;
    border-color: $border-primary-color;
    height: 2px;
    background-color: $border-primary-color;
}

.title {
    cursor: pointer;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    margin-bottom: -12px;
}

.logout {
    justify-content: start;
}

.active {
    background-color: $secondary_color;
    padding: 12px 16px;
    border-radius: 3px;
    color: white !important;
}


@media screen and (max-width: 768px) {
    .dashboard-side-bar {
        display: none;
    }

    .side-bar-open {
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100vw;
        z-index: 100;
        animation: animateSideBar ease-in-out 0.3s forwards;

        .close-icon {
            display: block;
            position: absolute;
            top: 32px;
            right: 20px;
        }
    }

    .side-bar-closed {
        animation: animateSideBarBack ease-in-out 0.3s forwards;
    }

    @keyframes animateSideBar {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0);
        }
    }

    @keyframes animateSideBarBack {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(100%);
        }
    }
}