@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
body {
  background-color: #F9F9F9;
}

.content {
  min-height: 100vh;
}

.page {
  background-color: #F9F9F9;
}

.content {
  padding: 1rem;
}

.main-div {
  display: flex;
  gap: 1rem;
}

.menu-title {
  margin-block: 1rem;
  display: flex;
  gap: 13px;
  align-items: center;
}
.menu-title h2 {
  font-weight: 500;
  font-size: 18px;
}
.menu-title .count {
  background-color: #FFFFFF;
  width: 37px;
  height: 31px;
  padding: 6px 10px;
  border-radius: 12px;
  font-family: "Rubik", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.menu .menu-item {
  height: 77px;
  border-radius: 24px;
  min-width: 250px;
  display: flex;
  background-color: #FFFFFF;
  align-items: center;
  gap: 17px;
  padding: 12px 24px 12px 24px;
}
.menu .menu-item .menu-image {
  width: 40px;
}
.menu .menu-item .menu-image img {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
}
.menu .menu-item .menu-item-description {
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.menu .menu-item .menu-item-description .last-row {
  display: flex;
  gap: 24px;
  align-items: center;
}
.menu .menu-item .menu-item-description .last-row .price-div {
  display: flex;
  align-items: center;
  gap: 4px;
}
.menu .menu-item .menu-item-description .last-row .price {
  font-weight: 500;
}
.menu .menu-item .menu-item-description .last-row .served,
.menu .menu-item .menu-item-description .last-row span {
  color: #676767;
}
.menu .menu-item .menu-item-description .last-row .currency {
  font-size: 12px;
}

.order-container {
  width: 324px;
  height: 686px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 24px;
}
.order-container .order-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.order-container .order-popup .popup-content {
  background-color: #FFFFFF;
  padding: 12px 24px 12px 24px;
  padding-top: 19px;
  position: relative;
  max-width: 744px;
  height: 480px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 60%;
}
.order-container .order-popup .popup-content h2 {
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 33px;
}
.order-container .order-popup .popup-content .order-details-div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.order-container .order-popup .popup-content .item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.order-container .order-popup .popup-content .item .price {
  display: flex;
  gap: 8px;
  align-items: center;
}
.order-container .order-popup .popup-content .item .price p {
  font-weight: 500;
}
.order-container .order-popup .popup-content .item .price span {
  font-size: 12px;
}
.order-container .order-popup .popup-content .popup-total {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: absolute;
  bottom: 0;
  gap: 2rem;
}
.order-container .order-popup .popup-content .popup-total h3 {
  font-weight: 500;
  font-size: 16px;
}
.order-container .order-popup .popup-content .second-row {
  display: flex;
  flex-direction: column;
}
.order-container .order-popup .popup-content .second-row .anonymous-user-form {
  display: flex;
  gap: 1rem;
  padding-bottom: 20px;
}
.order-container .order-popup .popup-content .second-row .anonymous-user-form input {
  background-color: #f3f3f3;
  padding: 15px 15px;
  border-radius: 12px;
  border: none;
  outline: none;
  font-family: "Outfit", sans-serif;
  max-width: 180px;
}
.order-container .order-popup .popup-content .second-row .first-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-container .order-popup .popup-content .second-row .first-line .close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-bottom: 33px;
}
.order-container .order-popup .popup-content .second-row .department {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.order-container .order-popup .popup-content .second-row .department .department-title {
  padding-bottom: 0;
}
.order-container .order-popup .popup-content .second-row .department .departments-div {
  width: 377px;
  height: 190px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.order-container .order-popup .popup-content .second-row .department .departments-div .department-item {
  width: 182px;
  height: 46px;
  background-color: #f5f5f5;
  padding: 13px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.order-container .order-popup .popup-content .second-row .department .departments-div .department-item.active {
  border: 1px solid #fde5b6;
}
.order-container .order-popup .popup-content .second-row .buttons {
  display: flex;
  gap: 16px;
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  right: 60px;
}
.order-container .order-popup .popup-content .second-row .buttons .cancel,
.order-container .order-popup .popup-content .second-row .buttons .send {
  width: 183px;
  height: 44px;
  display: flex;
  border-radius: 8px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 54px;
  cursor: pointer;
}
.order-container .order-popup .popup-content .second-row .buttons .cancel {
  background-color: #e5e5e5;
  color: #767676;
  padding: 12px 54px;
}
.order-container .order-popup .popup-content .second-row .buttons .send {
  background-color: #F87C47;
  color: #FFFFFF;
  padding: 12px 34px;
}
.order-container .success-div {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.order-container .success-div .success-content {
  background-color: #FFFFFF;
  padding: 12px 24px 12px 24px;
  position: relative;
  max-width: 611px;
  height: 406px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 77px;
}
.order-container .success-div .success-content .first-line h3 {
  position: absolute;
  top: 18px;
  left: 22px;
}
.order-container .success-div .success-content .first-line img {
  position: absolute;
  top: 18px;
  right: 22px;
  cursor: pointer;
}
.order-container .success-div .success-content .content {
  flex-direction: column;
  width: 422px;
  height: 120px;
  gap: 12px;
  text-align: center;
  display: flex;
  align-items: center;
}
.order-container .success-div .success-content .content img {
  width: 136px;
  height: 80px;
}
.order-container .success-div .success-content .content h2 {
  font-weight: 600;
  font-size: 20px;
  color: #F87C47;
}
.order-container .success-div .success-content .content p {
  color: #696969;
}
.order-container .success-div .success-content .button {
  background-color: #F87C47;
  width: 201px;
  height: 48px;
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 10px;
  padding: 12px 34px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.order-container .success-div .success-content .button a {
  color: #FFFFFF;
}

.order-details {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 12px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
.order-details .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
.order-details .title h2 {
  font-weight: 500;
  font-size: 18px;
}
.order-details .title .date {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #8f8f8f;
}
.order-details .orders {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.order-details .order-item {
  width: 282px;
  height: 90px;
  border-radius: 12px;
  padding: 12px 16px;
  gap: 7px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}
.order-details .order-item .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-details .order-item .name h2 {
  font-size: 18px;
  font-weight: 400;
}
.order-details .order-item .price {
  display: flex;
  justify-content: space-between;
}
.order-details .order-item .price .cost {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
.order-details .order-item .price p:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
}
.order-details .details {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}
.order-details .quantity {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  width: 86px;
  height: 36px;
  padding: 8px;
  gap: 10px;
  border-radius: 12px;
}
.order-details .total {
  display: flex;
  flex-direction: column;
  gap: 9px;
  position: absolute;
  bottom: 30px;
}
.order-details .total h3 {
  font-size: 16px;
  font-weight: 400;
  color: #8d8d8d;
}
.order-details .total .price {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  padding-bottom: 24px;
}
.order-details .total .price p:nth-child(2) {
  font-size: 16px;
  font-weight: 400;
}
.order-details .total .button {
  background-color: #F87C47;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 271px;
  height: 48px;
  border-radius: 12px;
  padding: 12px 10px;
}
.order-details .total .button a {
  text-decoration: none;
  color: #FFFFFF;
}

.view-order {
  display: none;
}

@media screen and (max-width: 1200px) {
  .category {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 968px) {
  .header {
    position: absolute;
    top: 0;
    left: 0;
  }
  .header .nav .menu-btn,
  .header .nav .history-btn {
    display: none;
  }
  .header .search-bar {
    display: none;
  }
  .header .notifications-div {
    display: flex;
    gap: 15px;
  }
  .header .mobile-menu {
    display: flex;
    cursor: pointer;
    border-radius: 12px;
  }
  .header .mobile-menu .menu-div .dropdown {
    position: absolute;
    padding: 12px 24px 12px 24px;
    left: 0;
    z-index: 1;
    background-color: #FFFFFF;
    width: 100vw;
  }
  .header .mobile-menu .menu-div .dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .header .mobile-menu .menu-div .dropdown ul li {
    height: 48px;
  }
  .header .mobile-menu .menu-div .dropdown ul li .menu {
    background-color: #282c7d;
    color: #fff;
  }
  .header .mobile-menu .menu-div .dropdown ul li .history {
    background-color: #f6f6f6;
    color: #6c6a6a;
  }
  .header .mobile-menu .menu-div .dropdown ul li a {
    width: 100%;
    padding: 12px 24px 12px 24px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  main .main-div {
    display: block;
    flex: 1;
  }
  main .view-order {
    display: flex;
    padding: 20px 120px;
    justify-content: center;
  }
  main .view-order a {
    color: #fff;
    background-color: #1c274c;
    min-width: 377px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }
  main .first-row {
    width: 100%;
  }
  main .first-row .category {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    justify-content: center;
    padding: 12px 24px 12px 24px;
  }
  .main-div .order-details {
    display: none;
  }
}
@media screen and (max-width: 530px) {
  main .first-row .menu {
    flex-direction: column;
  }
  main .first-row .category {
    gap: 10px;
  }
  main .first-row .category .category-item.active {
    gap: 1rem;
    width: 130px;
  }
  main .first-row .category .category-item {
    gap: 1rem;
  }
  main .first-row .category .category-item .image {
    width: 42px;
  }
}/*# sourceMappingURL=home.css.map */