$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;
$primary_gray: #8a8a8a;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.requesting-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes loadingAnimation {
        0% {
            opacity: 0;
        }
        25% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    .requesting-dots {
        display: flex;
    }

    .requesting-dot {
        display: inline-block;
        animation: loadingAnimation 1s infinite;
    }

    .requesting-dot:nth-child(1) {
        animation-delay: 0.1s;
    }

    .requesting-dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .requesting-dot:nth-child(3) {
        animation-delay: 0.3s;
    }
}

.leaves-section {

    .popup {
        // place-content: center;
        .add-leave {
            background-color: $white_color;
            padding: 24px;
            border-radius: $border_radius;
            display: flex;
            flex-direction: column;
            gap: $medium_gap;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    background: transparent;
                    font-size: 24px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                gap: $big_gap;

                .date-input-fields {
                    display: flex;
                    gap: 16px;

                    input {
                        width: 255px;
                    }
                }

                .btns {
                    display: flex;
                    gap: 24px;
                    justify-content: right;

                    button {
                        padding: 16px;
                    }

                    .cancel-btn {
                        border: 1px solid #ebebeb;
                        background: transparent;
                        color: $gray_color;
                    }

                    .add-btn {
                        background-color: $secondary_color;
                        color: $white_color;
                    }
                }
            }
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        position: relative;

        .underline {
            background-color: #f1f1f1;
            width: 100%;
            height: 1px;
        }

        .tabs {
            background-color: $white_color;
            margin: 15px 30px;
            padding: 15px 10px;
            border-radius: $border_radius;
            display: flex;
            gap: calc($big_gap * 2);

            button {
                background: transparent;
                font-size: 16px;
                color: $primary_gray;
                display: flex;
                gap: 6px;
                align-items: center;
            }

            .icon {
                width: 30px;
                height: 30px;
                padding: 5px;
                border-radius: 50%;
                display: block;
            }

            .leave-requests-number {
                background-color: $secondary_color;
                width: 30px;
                height: 30px;
                padding: 5px;
                border-radius: 50%;
                display: block;
                color: $white_color;
            }

            .active {
                color: $secondary_color;
            }
        }

        .numbers {
            background-color: $white_color;
            margin: 15px 30px;
            padding: 15px 40px;
            border-radius: $border_radius;

            .numbers-details {
                display: flex;
                gap: 40px;

                .col-details {
                    display: flex;
                    flex-direction: column;
                    gap: calc($medium_gap / 2);
                    .col-details-numbers {
                        display: flex;
                        align-items: center;
                        gap: calc($medium_gap / 2);

                        .percentage {
                            background-color: $light_green;
                            padding: 4px 8px;
                            border-radius: $border_radius;

                            p {
                                color: $primary_green;
                                font-size: 12px;
                                text-align: center;
                                padding: 0;
                            }
                        }
                    }

                    p {
                        color: $gray_color;
                    }
                }

                .col-details:nth-child(2),
                .col-details:nth-child(3),
                .col-details:nth-child(4),
                .col-details:nth-child(5),
                .col-details:nth-child(6),
                .col-details:nth-child(7) {
                    border-left: 3px solid #f6f6f6;

                    h4 {
                        padding-left: 12px;
                    }

                    p {
                        padding-left: 12px;
                    }
                }
            }
        }

        .contents-container {
            .title-contents {
                .top {
                    .col {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        p {
                            color: $gray_color;
                        }
                        span {
                            color: $secondary_color;
                        }

                        button {
                            background: transparent;
                            padding: 10px 24px;
                            color: $primary_gray;
                            border: 1px solid $primary_gray;
                        }

                        .icon {
                            color: $primary_gray;
                        }

                        .leave-btns {
                            display: flex;
                            align-items: center;
                            gap: $medium_gap;

                            .calendar-list-btns {
                                display: flex;
                                align-items: center;

                                button {
                                    border: transparent;
                                    padding: 4px 8px;
                                    font-size: 24px;
                                    border-radius: 0px;
                                }

                                .list-btn {
                                    border: 1px solid $primary_gray;
                                    border-top-left-radius: $border_radius;
                                    border-bottom-left-radius: $border_radius;
                                }

                                .calendar-btn {
                                    border: 1px solid $primary_gray;
                                    border-top-right-radius: $border_radius;
                                    border-bottom-right-radius: $border_radius;
                                }

                                .active {
                                    background-color: $secondary_color;
                                    color: $white_color;
                                    border: 1px solid $secondary_color;
                                }
                            }

                            .add-leave-btn {
                                border: transparent;
                                background-color: $secondary_color;
                                color: $white_color;

                                span {
                                    color: $white_color;
                                }
                            }

                            .recent-btn {
                                span {
                                    color: $primary_gray;
                                }
                            }
                        }
                    }
                }

                .input-fields {
                    padding-top: 10px;

                    .input {
                        position: relative;

                        input {
                            padding-left: 10px;
                            padding-right: 50px;
                        }

                        .icon {
                            position: absolute;
                            left: 220px;
                            top: 11px;
                            color: $gray_color;
                        }
                    }
                }
            }

            .table-contents {
                p {
                    color: $primary_color;
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 540px) {
    .leaves-section {
        .popup {
            .add-leave {
                width: 100%;

                form {
                    .date-input-fields {
                        flex-direction: column;

                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .row {
            .tabs {
                margin: 15px 10px;
            }

            .numbers {
                margin: 15px 10px;

                .numbers-details {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    .col-details {
                        h4 {
                            font-size: 14px;
                        }

                        p {
                            font-size: 12px;
                        }
                    }

                    .col-details:nth-child(3) {
                        border-left: none;

                        h4 {
                            padding-left: 0;
                        }

                        p {
                            padding-left: 0;
                        }
                    }
                }
            }

            .contents-container {
                .top {
                    .leave-btns {
                        .text {
                            display: none;
                        }

                        span {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}
