@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.overview-main {
  margin-top: 20px;
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}
.overview-main .overview-left {
  width: 100%;
  flex: 1;
}
.overview-main .overview-right {
  width: 55%;
  flex: 1 1 500px;
}

.coursescard-main {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Outfit", sans-serif;
  height: 200px;
  width: 100%;
}
.coursescard-main .courses-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coursescard-main .courses-view h4 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}
.coursescard-main .courses-view h3 {
  color: #F87C47;
  font-size: 12px;
  font-weight: 500;
}

.recharts-surface {
  width: 100%;
}

.piechart-numbers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.completed-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.completed-text {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.completed-text h3 {
  font-size: 14px;
  font-weight: 400;
}

.completed-percentage {
  display: flex;
  align-items: center;
  gap: 10px;
}
.completed-percentage h4 {
  font-size: 16px;
}
.completed-percentage .orange-line {
  height: 15px;
  width: 5px;
  background-color: #F87C47;
  border-radius: 12px;
}
.completed-percentage .blue-line {
  height: 15px;
  width: 5px;
  background-color: #145C9E;
  border-radius: 12px;
}
.completed-percentage p {
  font-size: 10px;
  color: #F87C47;
  width: 42px;
  height: 21px;
  background-color: #FEF6F3;
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.cohort-details-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pageheader-main {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  margin-top: 10px;
  flex-wrap: wrap;
}
.pageheader-main .pageheader-content {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.pageheader-main .pageheader-content .pageheader-icon {
  background-color: #EFF7FF;
  border-radius: 12px;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageheader-main .leadership-header {
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex: 1;
}
.pageheader-main .leadership-header p {
  font-size: 16px;
  color: #817A7A;
}
.pageheader-main .leadership-header .leadership-bold {
  display: flex;
  gap: 10px;
  align-items: center;
}
.pageheader-main .leadership-header .leadership-bold h1 {
  font-size: 20px;
  font-weight: 500;
}
.pageheader-main .leadership-header .leadership-bold h2 {
  background-color: #F6FBFF;
  color: #145C9E;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 4px;
}
.pageheader-main .pageheader-numbers {
  display: flex;
  gap: 30px;
  padding-left: 20px;
  flex: 1;
}
.pageheader-main .pageheader-numbers .numbers-content {
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 30px;
  padding-block: 15px;
}
.pageheader-main .pageheader-numbers .numbers-content p {
  color: #A5A3A9;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  line-height: 30px;
}
.pageheader-main .pageheader-numbers .numbers-content .arrow-numbers {
  display: flex;
  align-items: center;
}
.pageheader-main .pageheader-numbers .numbers-content .arrow-numbers h1 {
  font-size: 18px;
  font-weight: 500;
}

.courses-quizzes {
  display: flex;
  gap: 20px;
  width: 100%;
}

.department-main {
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
}
.department-main .department-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.department-main .department-header h1 {
  font-size: 18px;
  font-weight: 500;
}
.department-main .department-header h4 {
  font-size: 15px;
  color: #F87C47;
  font-weight: 500;
}
.department-main .color-lines {
  display: flex;
  gap: 10px;
  padding-top: 30px;
}
.department-main .color-lines .color1 {
  max-width: 101px;
  height: 24px;
  width: 100%;
  background-color: #145C9E;
  border-radius: 4px;
}
.department-main .color-lines .color2 {
  max-width: 67px;
  height: 24px;
  width: 100%;
  background-color: #5C98CF;
  border-radius: 4px;
}
.department-main .color-lines .color3 {
  max-width: 56px;
  height: 24px;
  width: 100%;
  background-color: #D2E1EE;
  border-radius: 4px;
}
.department-main .color-lines .color4 {
  max-width: 92px;
  height: 24px;
  width: 100%;
  background-color: #FBC6B0;
  border-radius: 4px;
}
.department-main .color-lines .color5 {
  max-width: 150px;
  height: 24px;
  width: 100%;
  background-color: #F87C47;
  border-radius: 4px;
}
.department-main .department-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 30px;
  gap: 10px;
  align-items: center;
}
.department-main .depart-num-percentage {
  display: flex;
  gap: 10px;
  align-items: center;
}
.department-main .depart-num-percentage p {
  background-color: #FEF7F4;
  width: 28px;
  height: 17px;
  border-radius: 4px;
  color: #F87C47;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.department-main h3 {
  font-size: 16px;
  color: #8A8A8A;
  font-weight: 400;
}
.department-main h4 {
  font-size: 20px;
  font-weight: 500;
}
.department-main .department-txt-num {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.department-main .department-text {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.recent-transactions {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
}
.recent-transactions .card-header {
  padding-bottom: 20px;
}

@media screen and (max-width: 1250px) {
  .pageheader-main .pageheader-numbers .numbers-content {
    padding: 20px;
    padding-block: 10px;
  }
}
@media screen and (max-width: 850px) {
  .courses-quizzes {
    flex-direction: column;
  }
}
@media screen and (max-width: 780px) {
  .pageheader-main .pageheader-numbers {
    gap: 20px;
    padding-left: 0px;
  }
}
@media screen and (max-width: 480px) {
  .pageheader-main .pageheader-numbers {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .pageheader-main .pageheader-numbers .numbers-content {
    width: 100%;
  }
  .department-main .department-content {
    grid-template-columns: 1fr 1fr;
  }
}/*# sourceMappingURL=trainingcohortoverview.css.map */