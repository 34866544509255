$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

.reset-password {
    display: grid;
    place-content: center;
    height: 100vh;

    .requesting-container {
        display: flex;
        justify-content: center;
        align-items: center;
    
        @keyframes loadingAnimation {
            0% {
                opacity: 0;
            }
            25% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 0;
            }
        }
    
        .requesting-dots {
            display: inline-block;
        }
    
        .requesting-dot {
            display: inline-block;
            animation: loadingAnimation 1s infinite;
        }
    
        .requesting-dot:nth-child(1) {
            animation-delay: 0.1s;
        }
    
        .requesting-dot:nth-child(2) {
            animation-delay: 0.2s;
        }
    
        .requesting-dot:nth-child(3) {
            animation-delay: 0.3s;
        }
    }

    .col {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        h1 {
            color: $primary_color;
        }

        .title {
            color: $secondary_color;
        }

        .subtitle {
            font-weight: bold;
            color: #000;
        }

        p {
            color: $gray_color;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .input {
                position: relative;

                .icon {
                    position: absolute;
                    left: 8px;
                    top: 11px;
                    width: 24px;
                    height: 24px;
                    color: $gray_color;
                }

                input {
                    width: 100%;
                }
            }

            .message {
                color: $secondary_color;
            }

            button {
                background-color: $secondary_color;
                padding: 12px 24px;
                color: $white_color;
            }

            .login-btn {
                background: transparent;
                padding: 0;
                text-align: center;
                a {
                    color: $secondary_color;
                }
            }
        }
    }
}