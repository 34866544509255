.fc-theme-standard .fc-scrollgrid {
  border: 1px solid #fff7f3;
}

.fc .fc-toolbar-title {
  font-size: 20px;
}

.fc .fc-button-primary {
  text-transform: capitalize;
  background-color: #145c9e;
  opacity: 1.1;
  border: transparent;
}

.fc .fc-button:disabled {
  text-transform: capitalize;
  background-color: #145c9e;
  opacity: 1.1;
  border: transparent;
}

.fc-event {
  background-color: #dceeff;
  border: transparent;
  padding-inline: 10px;
  width: -moz-fit-content;
  width: fit-content;
}

.fc .fc-daygrid-event {
  margin-top: 10px;
  z-index: 6;
}
.fc .fc-daygrid-event .event-title {
  color: #145c9e;
}

.fc-h-event .fc-event-main {
  color: #145c9e;
  font-weight: 400;
}

.fc-daygrid-event {
  white-space: wrap;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 10px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin-left: 10px;
}

.leaves-schedule {
  display: flex;
  gap: 24px;
}
.leaves-schedule .table-content {
  width: 70%;
}
.leaves-schedule .table-content table thead {
  background-color: #fafafa;
}
.leaves-schedule .table-content table th {
  font-weight: 200;
  color: #a5a3a9;
  border: 1px solid #fff7f3;
}
.leaves-schedule .table-content table td {
  color: #666565;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #fff7f3;
}
.leaves-schedule .upcoming-leaves {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.leaves-schedule .upcoming-leaves .title {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.leaves-schedule .upcoming-leaves .title p {
  color: #979797;
}
.leaves-schedule .upcoming-leaves .leave-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.leaves-schedule .upcoming-leaves .leave-details .leave {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.leaves-schedule .upcoming-leaves .leave-details .leave h4 {
  font-size: 16px;
  font-weight: 400;
}
.leaves-schedule .upcoming-leaves .leave-details .leave p {
  font-size: 14px;
  color: #979797;
}
.leaves-schedule .upcoming-leaves .leave-details .leave:nth-child(odd) {
  background-color: #fffcf6;
  border-left: 2px solid #ffe2d5;
}
.leaves-schedule .upcoming-leaves .leave-details .leave:nth-child(even) {
  background-color: #f7fbff;
  border-left: 2px solid #dff0ff;
}

@media screen and (max-width: 768px) {
  .leaves-schedule {
    flex-direction: column;
  }
  .leaves-schedule .table-content {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .fc-event {
    visibility: hidden;
  }
  .fc .fc-daygrid-event-harness {
    background-color: #fff7f3;
  }
}/*# sourceMappingURL=calendar.css.map */