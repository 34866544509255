.trainingApplicantsCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  gap: 20px;
  width: 100%;
}

.trainingApplicantsCards h4 {
  align-self: flex-start;
  font-family: "Lexend", sans-serif;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 16px;
  padding: 0;
  margin: 0;
  margin-top: 6px;
  width: 100%;
}

.trainingApplicantsCard {
  flex: 1 1 300px;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
}

.trainingApplicantsCardsTitle {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 16px;
  margin-bottom: 18px;
  margin-top: 12px;
}

/* kkkkkkkkkkkkkkkkkkk */
.trainingApplicantsCardsTitle img {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
}

.trainingApplicantsCardsTitle .trainingApplicantsCardsTitleImg {
  background-color: #F8FCFE;
  border-radius: 10px;
  margin: 0;
  padding: 7px;
  box-sizing: border-box;
  align-items: center;
}

.trainingApplicantsCardsTitle h4 {
  align-self: flex-start;
  font-family: "Lexend", sans-serif;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100;
  margin-top: 6px;
}

.trainingApplicantsInfoCard {
  background-color: #fafafa;
  border-radius: 7px;
  margin: 0;
  padding: 0;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
}

.trainingApplicantsInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0;
  margin: 0;
  gap: 6px;
}

.infoCardp1 {
  align-self: flex-start;
  font-family: "Lexend", sans-serif;
  font-weight: normal;
  color: #AAAAAA;
  text-align: left;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.infoCardp2 {
  align-self: flex-start;
  font-family: "Lexend", sans-serif;
  font-weight: normal;
  color: #000000;
  text-align: left;
  font-size: 14px;
  padding: 0;
  margin: 0;
  margin-top: 3px;
}

.flex-child {
  flex: 1 1 500px;
  width: 100%;
}

.applications-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
  padding-top: 12px;
  width: 100%;
  flex-wrap: wrap;
}

.actions-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  justify-content: space-between;
  width: 100%;
}
.actions-filters .search-header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.actions-filters .search-header h1 {
  font-size: 18px;
  font-weight: 500;
}
.actions-filters .search-header p {
  font-size: 14px;
  color: #A5A3A9;
}
.actions-filters .search-header span {
  color: #F87C47;
  padding-right: 6px;
}
.actions-filters .search-input {
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid whitesmoke;
  padding-right: 4px;
}
.actions-filters .search-input input {
  border: 0;
}
.actions-filters .search-input button {
  padding: 8px 12px;
}
.actions-filters .filters {
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-header .view-all {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #F87C47;
}

.cohorts-and-quizzes-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 12px;
}
.cohorts-and-quizzes-container .cohorts-container {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 24px;
  flex: 1 1 500px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card {
  flex: 1 1 320px;
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .title-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .title-status .status {
  padding: 6px;
  background-color: #F6FBFF;
  color: #145C9E;
  font-size: 10px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .title-status .active {
  background-color: #F87C47;
  color: white;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .dates {
  color: gray;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .dates p {
  font-size: small;
  font-weight: lighter;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .cohort-numbers {
  display: flex;
  align-items: center;
  gap: 12px;
}
.cohorts-and-quizzes-container .cohorts-container .cohorts-list .cohort-card .cohort-numbers .number {
  background-color: whitesmoke;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  font-size: small;
}
.cohorts-and-quizzes-container .quizzes-container {
  flex: 1 1 400px;
  background-color: white;
}

.department-transactions {
  display: flex;
  align-items: baseline;
  gap: 12px;
  flex-wrap: wrap;
}
.department-transactions .departments {
  flex: 1 1 500px;
  background-color: white;
  padding: 12px;
  border-radius: 12px;
}
.department-transactions .departments .departments-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  height: -moz-fit-content;
  height: fit-content;
}
.department-transactions .departments .departments-list .department {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0;
}
.department-transactions .departments .departments-list .department .title {
  color: gray;
  display: flex;
  align-items: center;
  gap: 6px;
  width: -moz-fit-content;
  width: fit-content;
}
.department-transactions .departments .departments-list .department .title .department-name {
  font-size: 14px;
  font-weight: 300;
}
.department-transactions .departments .departments-list .department .title .shape {
  height: 28px;
  width: 15px;
  background-color: #F87C47;
  border-radius: 12px;
}
.department-transactions .departments .departments-list .department .trainees-count {
  position: relative;
}
.department-transactions .departments .departments-list .department .trainees-count .percentage {
  position: absolute;
  top: 0;
  left: 20px;
}
.department-transactions .recent-transactions {
  background-color: white;
  flex: 1 1 500px;
  padding: 12px;
  border-radius: 12px;
}

.quizzes-container {
  padding: 12px;
  background-color: white;
  border-radius: 12px;
  display: grid;
  gap: 32px;
}
.quizzes-container .quizzes-list {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
}
.quizzes-container .quizzes-list .quiz {
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 12px;
  border-radius: 6px;
}
.quizzes-container .quizzes-list .quiz .title-date {
  display: grid;
  gap: 12px;
  height: -moz-fit-content;
  height: fit-content;
}
.quizzes-container .quizzes-list .quiz .title-date .date-attendees {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  color: gray;
}
.quizzes-container .quizzes-list .quiz .title-date .date-attendees .date,
.quizzes-container .quizzes-list .quiz .title-date .date-attendees .attendees {
  display: flex;
  align-items: center;
  gap: 4px;
}/*# sourceMappingURL=cohorts.css.map */