.projecttracking{
  display: flex;
  gap: 10px;
  flex-direction: column;
  
}


.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}


.first-part{
  display: flex;
  gap: 20px;
  padding-inline: 20px;
  background-color: white;
  width: 100%;
  height: 169px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  
  .gray{
    background-color: #ededed;
    width: 320px;
    height: 116px;
    border-radius: 8px;
  }
}

.second-part{
  background-color: white;
  display: flex;
  width: 100%;
  gap: 50px;
  height: 70px;
  border-radius: 12px;
  padding-inline: 20px;
  
  .second-half{
     display: flex;
     align-items: center;
     gap: 10px;
    .long{
      background-color: #ededed;
      width: 235px;
      height: 26px;
    }
    .short{
      background-color: #ededed;
      width: 46px;
      height: 26px;
    }
  }

  .parttwo-half{
    display: flex;
      align-items: center;
     
    .longest{
      
      background-color: #ededed;
      width: 462px;
      height: 48px;
    }
  }

  .partthree-half{
    display: flex;
    gap: 10px;
    align-items: center;
    .whole{
      background-color: #ededed;
    width: 117px;
    height: 48px;
    }
   
  }
}

.thrid-part{
  background-color: white;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  
  border-radius: 12px;
  .thrid-half{
    background-color: #ededed;
    height: 52px;
    border-radius: 8px;
  }

  .columns{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 1400px) {
  .second-part{
    gap: 20px;
  }
}

@media (max-width: 1300px) {
  .second-part{
    gap: 60px;
    height: fit-content;
    padding: 10px;
    justify-content: center;
    .second-half{
      .long{
        width: 130px;
      }
    }

    .parttwo-half{
      .longest{
        width: 300px;
      }
    }

    .partthree-half{
      .whole{
        width: 90px;
      }
    }
  }

  .thrid-part{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1080px) {
  .second-part{
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .second-part{
    display: grid;
    grid-template-columns: 1fr;
  }
}


@media (max-width: 760px) {

  .thrid-part{
    grid-template-columns: 1fr 1fr;
  }

  .first-part{
    
    height: fit-content;
    padding-block: 20px;
    justify-content: center;
    align-items: center;

    .gray{
      width: 200px;
    }
  }
}
@media (max-width: 550px) {

  .thrid-part{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .first-part{
    flex-direction: column;
    width: 100%;

    .gray{
      width: 100%;
      height: 50px;
    }
  }
}
