.not-found {
  display: grid;
  place-items: center;
  height: 80vh;
}
.not-found .container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
}
.not-found .container h2 {
  font-size: 40px;
  color: #145c9e;
}
.not-found .container button {
  background-color: #f87c47;
  color: #ffffff;
  padding: 16px 24px;
  font-size: 18px;
}/*# sourceMappingURL=not-found.css.map */