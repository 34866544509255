.login-section {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  padding-inline: 80px;
  gap: 80px;
}
.login-section img {
  width: 100%;
  height: 90vh;
  max-width: 600px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}
.login-section .col {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  max-width: 600px;
}
.login-section .col h1 {
  color: #145c9e;
}
.login-section .col .message {
  color: #f87c47;
}
.login-section .col span {
  color: #f87c47;
}
.login-section .col p {
  color: #979797;
  font-size: 18px;
}
.login-section .col form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.login-section .col form .login-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.login-section .col form .login-form .input {
  border: 1px solid #979797;
  display: flex;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.login-section .col form .login-form .input .icon {
  color: #979797;
  font-size: 20px;
}
.login-section .col form .login-form .input input {
  width: 100%;
  outline: none;
  background-color: #fafafa;
  border: none;
  font-size: 16px;
}
.login-section .col form .login-form .forgot-password {
  text-align: right;
}
.login-section .col form .login-form .forgot-password button {
  background: transparent;
  border: transparent;
  color: #f87c47;
}
.login-section .col form .login-form button {
  padding: 11px 26px;
  background-color: #f87c47;
  color: #ffffff;
}
.login-section .col form .login-form .login-btn {
  padding: 16px 26px;
  width: 100%;
  font-size: 16px;
}
.login-section .col form .login-form .login-btn:hover {
  background-color: #145c9e;
}
.login-section .col form .reset-password {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.login-section .col form .reset-password p {
  width: 70%;
}
.login-section .col form .reset-password span {
  color: #000;
  font-weight: bold;
}
.login-section .col form .reset-password .input {
  position: relative;
}
.login-section .col form .reset-password .input .icon {
  position: absolute;
  left: 8px;
  top: 11px;
  width: 24px;
  height: 24px;
  color: #979797;
}
.login-section .col form .reset-password .input input {
  width: 100%;
}
.login-section .col form .reset-password .verify-email {
  background-color: #f87c47;
  padding: 11px 26px;
}
.login-section .col form .reset-password .verify-email .verify-email-link {
  color: #ffffff;
}
.login-section .col .social-media .google-btn {
  background-color: #f87c47;
  padding: 16px 28px;
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.login-section .col .social-media .google-btn:hover {
  background-color: #145c9e;
}

@media screen and (max-width: 1152px) {
  .login-section {
    padding-inline: 20px;
    gap: 24px;
  }
}
@media screen and (max-width: 1040px) {
  .login-section {
    padding-inline: 20px;
  }
  .login-section img {
    display: none;
  }
  .login-section .col {
    width: 100%;
  }
}/*# sourceMappingURL=login.css.map */