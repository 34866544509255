.chorts-page-contents {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.titles {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 200px;
    width: 100%;
    height: 25px;
    // background-color: #e0dddd;

    .divider {
        width: 6px;
        height: 20px;
        background-color: #797777;
    }

    .title-text {
        max-width: 95px;
        width: 100%;
        height: 20px;
        background-color: #ffffff;
    }
}

.transaction-filtering {
    // max-width:1376px ;
    width: 100%;
    height: 80px;
    background-color: #FFFF;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;




    .filtering-contents {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        gap: 10px;



        .transactions {
            max-width: 132px;
            width: 100%;
            height: 48px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            // background-color: #e0dddd;
            padding: 5px;

            .transaction-title {
                max-width: 103px;
                width: 100%;
                height: 24px;
                background-color: #F8F8F8;
            }

            .tansaction-numbers {
                max-width: 132px;
                height: 20px;
                background-color: #F8F8F8;
            }
        }

        .search-transactions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
            gap: 10px;
            max-width: 494px;
            width: 100%;
            height: 48px;
            background-color: #F8F8F8;
            border-radius: 8px;

            .search-placeholder {
                max-width: 300px;
                width: 100%;
                height: 24px;
                background-color: #FFFFFF;
            }

            .search-icon {
                max-width: 20px;
                width: 100%;
                height: 20px;
                background-color: #FFFFFF;
            }
        }

        .transaction-filter-button {
            display: flex;
            align-items: center;
            gap: 5px;
            max-width: 131px;
            width: 100%;
            height: 44px;
            background-color: #F8F8F8;
            padding: 15px;
            border-radius: 8px;

            .filter-text {
                max-width: 90px;
                width: 100%;
                height: 24px;
                background-color: #FFFFFF;
            }

            .filter-icon {
                max-width: 30px;
                width: 100%;
                height: 24px;
                background-color: #FFFFFF;
            }



        }
    }

}



.cohorts-contents {
    // background-color: #FFFFFF;
    border-radius: 12px;
    // padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .cohorts-cards {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;

        // width: 100%;
        // background-color: #FCFBFB;

        .contents-cards {
            max-width: 545px;
            width: 100%;
            height: 148px;
            background-color: #FFFFFF;
            border-radius: 12px;
            padding: 24px;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            gap: 10px;
            // align-items: center;
            // padding: 24px 12px;

            .card-text-btn {
                width: 100%;
                // height: 100%;
                display: flex;
                // align-items: center;
                // justify-content: space-between;
                gap: 10px;

                .text {
                    width: 100%;
                    // height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    .card-title {
                        max-width: 210px;
                        width: 100%;
                        height: 20px;
                        background-color: #F8F8F8;
                    }

                    .card-date {
                        max-width: 217px;
                        width: 100%;
                        height: 18px;
                        background-color: #F8F8F8;
                    }
                }

                .enrol-btn {
                    max-width: 149px;
                    width: 100%;
                    height: 34px;
                    border-radius: 4px;
                    background-color: #F8F8F8;
                }

            }

            .btns-nombers{
                display: flex;
                width: 100%;
                gap: 12px;

                .btn-number{
                    max-width:111px ;
                    width: 100%;
                    height: 34px;
                    background-color: #F8F8F8;
                    border-radius:5px ;
                    padding: 8px 16px;
                }
               
            }
        }
    }
}