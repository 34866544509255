.dashboard-content-container {
    display: flex;
    align-items: start;

    .dashboard-content {
        width: calc(100vw - 226px);
        background-color: #F5F5F5;
    }
}

@media screen and (max-width: 768px) {
    .dashboard-content-container {
        .dashboard-content {
            width: 100vw;
        }
    }
}