@import 'variables.scss';

main{
  min-height: 100vh;
}
.adminpanel{
  background-color: #F7F6F6;
}

.admin-container{
  padding-top: 1rem;
  display: flex;
  gap: 20px;
  padding-inline: 1rem;
  min-height: 100vh;

  .button{
    display: none;
  }
}

.admin-first-row {
  .button{
    display: none;
  }
  .categories {
    background-color: $secondary_background_color;
    min-width: 360px;
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    gap: 10px;
    color: #696969;
    border-radius: $border_radius;
    margin-bottom: 20px;
    text-align: center;

    span {
      width: 112px;
      padding: 14px 10px;
      cursor: pointer;

      &.active-category {
        border-bottom: 3px solid #F87C47;
        color: #1c274c;
      }
    }
  }

  .orders {
    max-height: 705px;
    overflow-y: auto;
    .item {
      display: flex;
      min-width: 358px;
      height: 81px;
      border-radius: $border_radius;
      padding: 16px;
      gap: 10px;
      background-color: $secondary_background_color;
      margin-bottom: 12px;
      justify-content: space-between;
      align-items: center;

      .order-description {
        h3 {
          font-weight: 500;
          font-size: 18px;
        }
        p {
          color: #676767;
        }
      }

      .price {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}

.second-row {
  padding-bottom: 2rem;
  display: flex;
  flex: 1;

  .order-details-admin {
    background-color: $secondary_background_color;
    min-width: 300px;
    flex: 1;
    width: 100%;
    height: 776px;
    position: relative;
    padding: $padding;
    padding-left: 44px;

    h2 {
      font-weight: 600;
      font-size: 18px;
      position: absolute;
      top: 29px;
      left: 44px;
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      gap: 35px;
      margin-top: 77px;
      margin-bottom: 35px;

      .name,
      .department {
        display: flex;
        flex-direction: column;
        gap: 8px;

        p {
          color: #b5b5b5;
        }
      }
    }

    .order-info {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .order-item {
        background-color: #f6f6f6;
        max-width: 909px;
        width: 100%;
        height: 87px;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        gap: 94px;

        .order-name {
          display: flex;
          gap: 12px;
          align-items: center;

          img{
            border-radius: 8px;
            max-width: 52px;
            max-height: 52px;
          }

          p {
            font-weight: 500;
          }
        }

        .order-quantity {
          display: flex;
          align-items: center;
          gap: 12px;
          p {
            color: #767676;
          }
          span,
          p {
            font-weight: 500;
          }
        }

        .price {

            display: flex;
            align-items: center;
            gap: 2px;
          span,
          p {
            font-weight: 500;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.buttons{
    display: flex;
    gap: 16px;
    position: absolute;
    bottom: 20px;
    right: 34px;
    

    .cancel,
    .send{
        
        height: 44px;
        display: flex;
        border-radius: 8px;
        gap: 8px;
        align-items: center;
        justify-content: center;
        
        padding:12px 54px;
        cursor: pointer;
    }

    .cancel{
      width: 183px;
        background-color: #E5E5E5;
        color: #767676;
        padding: 12px 54px;
    }

    .send{
      width: 193px;
        background-color: #F87C47;
        color: $secondary_background_color;
        padding: 12px 34px;

        

    }
}

.footer-div{
    height: 10vh;
    background-color: $secondary_background_color;
    width: 100%;
    position: absolute;
    left: 0;
}

@media screen and (max-width: 1055px) {
  .second-row .order-details-admin .order-info .order-item{
    gap: 50px;
  }
  
}

@media screen and (max-width: 655px) {
  .admin-details-page .admin-container .order-details-admin .order-info .order-item {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 968px) {
  
  
  .admin-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .categories{
      justify-content: space-between;
    }

    .admin-first-row{
      flex: 1;
      
      }

      .button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 2rem;
        
  
        a{
          color: $secondary_background_color;
          background-color: #1C274C;
          padding: 12px 54px;
          width: 283px;
          border-radius: $border-radius;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 44px;
  
        }
      

    }
    

   
  }
  .second-row{
    display: none;
  }

  
}

.admin-details-page{
  background-color: $background_color;
  .admin-container {
    padding-bottom: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
  
    .order-details-admin {
      background-color: $secondary_background_color;
      min-width: 300px;
      flex: 1;
      width: 100%;
      min-height: 776px;
      position: relative;
      padding: $padding;
      padding-left: 44px;
  
      h2 {
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        top: 29px;
        left: 44px;
      }
  
      .info {
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
        margin-top: 77px;
        margin-bottom: 35px;
  
        .name,
        .department {
          display: flex;
          flex-direction: column;
          gap: 8px;
  
          p {
            color: #b5b5b5;
          }
        }
      }
  
      .order-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
  
        .order-item {
          background-color: #f6f6f6;
          // width: 100%;
          // height: 87px;
          border-radius: 16px;
          padding: 16px;
          display: flex;
          gap: 94px;
          flex-wrap: wrap;
  
          .order-name {
            display: flex;
            gap: 12px;
            align-items: center;
  
            img{
              border-radius: 8px;
            }
  
            p {
              font-weight: 500;
            }
          }
  
          .order-quantity {
            display: flex;
            align-items: center;
            gap: 12px;
            p {
              color: #767676;
            }
            span,
            p {
              font-weight: 500;
            }
          }
  
          .price {
  
              display: flex;
              align-items: center;
              gap: 2px;
            span,
            p {
              font-weight: 500;
            }
  
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  
  .buttons{
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
      bottom: 20px;
      right: 34px;
      
  
      .cancel,
      .send{
          
          height: 44px;
          display: flex;
          border-radius: 8px;
          gap: 8px;
          align-items: center;
          justify-content: center;
          
          padding:12px 54px;
          cursor: pointer;
      }
  
      .cancel{
        width: 183px;
          background-color: #E5E5E5;
          color: #767676;
          padding: 12px 54px;
      }
  
      .send{
        width: 193px;
          background-color: $primary-color;
          color: $secondary_background_color;
          padding: 12px 34px;
  
          
  
      }
  }

  .footer-div{
    height: 10vh;
    background-color: $secondary_background_color;
    width: 100%;
    position: absolute;
    left: 0;
}
}