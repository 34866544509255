.projects .popup {
    place-content: center;
  }
  .projects .popup .edit-project {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .projects .popup .edit-project .title {
    display: flex;
    justify-content: space-between;
  }
  .projects .popup .edit-project .title button {
    background: transparent;
    font-size: 24px;
  }
  .projects .popup .edit-project form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .projects .popup .edit-project form .input-fields {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
  }
  .projects .popup .edit-project form .input-fields .first-field-col {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .projects .popup .edit-project form label {
    font-size: 14px;
  }
  .projects .popup .edit-project form input,
  .projects .popup .edit-project form select {
    width: 300px;
    color: #979797;
    outline: none;
  }
  .projects .popup .edit-project form textarea {
    resize: none;
    height: 235px;
    width: 100%;
  }
  .projects .popup .edit-project form input {
    padding: 16px 8px;
    outline: none;
  }
  .projects .popup .edit-project form select {
    padding: 16px 8px;
  }
  .projects .popup .edit-project form .radio-inputs {
    display: flex;
    gap: 24px;
  }
  .projects .popup .edit-project form .radio-inputs .radio-input-field {
    display: flex;
    gap: 6px;
  }
  .projects .popup .edit-project form .btns {
    display: flex;
    gap: 24px;
    justify-content: right;
  }
  .projects .popup .edit-project form .btns button {
    padding: 16px;
  }
  .projects .popup .edit-project form .btns .cancel-btn {
    border: 1px solid #ebebeb;
    background: transparent;
    color: #979797;
  }
  .projects .popup .edit-project form .btns .add-btn {
    background-color: #f87c47;
    color: #ffffff;
  }
  .projects .project-details-popup {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-items: center;
    justify-content: right;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .projects .project-details-popup .project-details {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    margin-right: 30px;
  }
  .projects .project-details-popup .project-details .project-row {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .projects .project-details-popup .project-details .project-row .icon {
    font-size: 20px;
  }
  .projects .project-details-popup .project-details .project-row .project-details-link {
    color: #000;
  }
  .projects .row {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .projects .row .underline {
    background-color: #f1f1f1;
    width: 100%;
    height: 1px;
  }
  .projects .contents-container .title-contents .top .col-title h4 {
    color: black;
  }
  .projects .contents-container .title-contents .top .col-title p {
    color: #979797;
    font-size: 14px;
  }
  .projects .contents-container .title-contents .top .col-title1 p {
    color: #979797;
    font-size: 12px;
  }
  .projects .row .col-2 .contents-container .title-contents .top .col-title button {
    background-color: #f87c47;
    padding: 10px 24px;
    color: #ffffff;
  }
  .projects .row .col-2 .contents-container .title-contents .input-fields {
    padding-top: 10px;
  }
  .projects .row .col-2 .contents-container .title-contents .input-fields .input {
    position: relative;
  }
  .projects .row .col-2 .contents-container .title-contents .input-fields .input input {
    padding-left: 15px;
    padding-right: 60px;
    outline: none;
  }
  .projects .row .col-2 .contents-container .title-contents .input-fields .input .icon {
    position: absolute;
    left: 220px;
    top: 11px;
    color: #979797;
  }
  .projects .contents-container .title-contents .input-fields .input .icon {
    position: relative;
    left: -30px;
    top: 4px;
    color: #979797;
  }
  .projects .contents-container .title-contents .input-fields .input input{
    outline: none;
  }
  
  .projects .row .col-2 .contents-container .title-contents .add-project-link {
    background-color: #f87c47;
    display: flex;
    align-items: center;
    gap: 6px;
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
  }
  
  .progress {
    color: #145c9e !important;
    border-radius: 8px;
  }
  
  .completed {
    color: #01a653 !important;
    border-radius: 8px;
  }
  
  .canceled {
    color: red !important;
    border-radius: 8px;
  }
  
  .pending {
    color: #f87c47 !important;
    border-radius: 8px;
  }
  
  .hidden {
    display: none;
  }
  
  .additional {
    position: absolute;
    left: 115px;
    top: 40px;
    font-weight: 600;
  }
  
  .no-project {
    margin-top: 20px;
    color: red;
    font-size: 24px;
    text-align: center;
  }
  
  @media screen and (max-width: 540px) {
    .projects .popup .edit-project {
      height: 100svh;
      overflow-y: scroll;
    }
    .projects .popup .edit-project form .btns {
      justify-content: left;
    }
  }