.leaves-overview-loader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 10px;
}
.leaves-overview-loader .overviews {
  display: flex;
  gap: 16px;
}
.leaves-overview-loader .overviews .cards {
  width: 40%;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.leaves-overview-loader .overviews .cards .card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}
.leaves-overview-loader .overviews .cards .card .icon {
  background-color: #f9f9f9;
  max-width: 48px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
}
.leaves-overview-loader .overviews .cards .card .title-text {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}
.leaves-overview-loader .overviews .cards .card .title-text .title {
  background-color: #f9f9f9;
  max-width: 188px;
  width: 100%;
  height: 26px;
  border-radius: 4px;
}
.leaves-overview-loader .overviews .cards .card .title-text .text-midle {
  background-color: #f9f9f9;
  max-width: 74px;
  width: 100%;
  height: 32px;
  border-radius: 4px;
}
.leaves-overview-loader .overviews .cards .card .title-text .text-bottom {
  background-color: #f9f9f9;
  max-width: 160px;
  width: 100%;
  height: 21px;
  border-radius: 4px;
}
.leaves-overview-loader .overviews .charts {
  width: 60%;
  padding: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
}
.leaves-overview-loader .overviews .charts .chart-title {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.leaves-overview-loader .overviews .charts .chart-title .title-text-chart {
  background-color: #f9f9f9;
  max-width: 188px;
  width: 100%;
  height: 26px;
  border-radius: 4px;
}
.leaves-overview-loader .overviews .charts .chart-title .button-chart {
  background-color: #f9f9f9;
  max-width: 88px;
  width: 100%;
  height: 22px;
}
.leaves-overview-loader .overviews .charts .chart-box-legend {
  display: flex;
  gap: 16px;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .chart-boxes {
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .chart-boxes .chart-box {
  background-color: #f9f9f9;
  width: 32px;
  height: 11px;
  border-radius: 9px;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .legends {
  display: flex;
  gap: 16px;
  width: 100%;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .legends .legend {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 13px;
  width: 100%;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .legends .legend .big {
  background-color: #f9f9f9;
  width: 18px;
  height: 211px;
  border-radius: 4px;
}
.leaves-overview-loader .overviews .charts .chart-box-legend .legends .legend .small {
  background-color: #f9f9f9;
  width: 18px;
  height: 106px;
  border-radius: 4px;
}
.leaves-overview-loader .contents {
  width: 100%;
  display: flex;
  gap: 24px;
}
.leaves-overview-loader .contents .left {
  width: 60%;
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.leaves-overview-loader .contents .left .content-title {
  display: flex;
  justify-content: space-between;
}
.leaves-overview-loader .contents .left .content-title .title-text {
  width: 65%;
  height: 31px;
  background-color: #f9f9f9;
}
.leaves-overview-loader .contents .left .content-title .button {
  width: 30%;
  height: 31px;
  background-color: #f9f9f9;
}
.leaves-overview-loader .contents .left .content-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.leaves-overview-loader .contents .left .content-body .content-text {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 67px;
  background-color: white;
  padding: 13px 10px 25px 10px;
}
.leaves-overview-loader .contents .left .content-body .content-text .text-field {
  background-color: #f9f9f9;
  width: 100%;
  max-width: 250px;
  height: 42px;
  border-radius: 8px;
}
.leaves-overview-loader .contents .right {
  width: 40%;
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.leaves-overview-loader .contents .right .content-title {
  display: flex;
  justify-content: space-between;
}
.leaves-overview-loader .contents .right .content-title .title-text {
  width: 60%;
  height: 31px;
  background-color: #f9f9f9;
}
.leaves-overview-loader .contents .right .content-title .button {
  width: 30%;
  height: 31px;
  background-color: #f9f9f9;
}
.leaves-overview-loader .contents .right .content-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.leaves-overview-loader .contents .right .content-body .content-text {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 67px;
  background-color: white;
  padding: 13px 10px 25px 10px;
}
.leaves-overview-loader .contents .right .content-body .content-text .text-field {
  background-color: #f9f9f9;
  width: 100%;
  max-width: 250px;
  height: 42px;
  border-radius: 8px;
}

@media screen and (max-width: 1270px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: flex;
  }
  .leaves-overview-loader .overviews {
    flex-direction: column;
  }
  .leaves-overview-loader .overviews .cards {
    width: 100%;
  }
  .leaves-overview-loader .overviews .charts {
    width: 100%;
  }
  .leaves-overview-loader .contents {
    flex-direction: column;
  }
  .leaves-overview-loader .contents .left {
    width: 100%;
  }
  .leaves-overview-loader .contents .right {
    width: 100%;
  }
}
@media screen and (max-width: 870px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: flex;
  }
}
@media screen and (max-width: 650px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}
@media screen and (max-width: 525px) {
  #fifth-chart-bar,
  #sixth-chart-bar {
    display: none;
  }
}
@media screen and (max-width: 390px) {
  #fourth-chart-bar {
    display: none;
  }
}/*# sourceMappingURL=leavesOverview.css.map */