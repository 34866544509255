.tox .tox-editor-container .tox-edit-area__iframe {
  direction: ltr !important;
}

.new-blog-page {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.new-blog-page .form {
  display: flex;
  align-items: start;
  gap: 1rem;
  flex-wrap: wrap;
}
.new-blog-page .form .main-content {
  flex: 1 1 400px;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.new-blog-page .form .extra-content {
  flex: 1 1 300px;
  max-width: 300px;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  position: relative;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 2rem;
}
.new-blog-page .form .extra-content .actions {
  display: grid;
  gap: 0.5rem;
  height: -moz-fit-content;
  height: fit-content;
}
.new-blog-page .form .extra-content .actions button {
  width: 100%;
  padding: 1rem;
}
.new-blog-page .form .input {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}

.author-list,
.department-list {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.author-list > :nth-child(even),
.department-list > :nth-child(even) {
  background-color: #feece4;
  color: #F87C47;
}

.author,
.department {
  background-color: #E8F3FD;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: relative;
  color: #145C9E;
}
.author .remove-item,
.department .remove-item {
  position: absolute;
  top: 0;
  right: 0;
  background-color: tomato;
  border-radius: 50%;
  color: white;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.categories-list .category-item {
  display: flex;
  background-color: #E8F3FD;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #145C9E;
  font-size: 10px;
}

.tags-list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.tags-list .tag {
  background-color: #E8F3FD;
  color: #145C9E;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 2rem;
}

.remove-item {
  cursor: pointer;
}

.select-item {
  background-color: #E8F3FD;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0.5rem;
}

.suggestions {
  display: grid;
  gap: 0.3rem;
  height: -moz-fit-content;
  height: fit-content;
}
.suggestions .suggestion {
  background-color: #E8F3FD;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: left;
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap;
}

.featured-image-placeholder {
  background-color: #fee5da;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  min-height: 180px;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.featured-image-placeholder img {
  width: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.5rem;
}

.error-message {
  background-color: #ffb9ad;
  padding: 1rem;
  border-radius: 0.5rem;
}

.success-message {
  background-color: #c2dfd2;
  padding: 1rem;
  border-radius: 0.5rem;
}

.input .close-button {
  display: none;
}

.popup-selection {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
}
.popup-selection .author-list,
.popup-selection .department-list {
  width: 100%;
}
.popup-selection .suggestions {
  width: 100%;
}
.popup-selection .close-button {
  display: block;
}

.card-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}/*# sourceMappingURL=newBlog.css.map */