.transaction-table-contents {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.titles {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 200px;
  width: 100%;
  height: 25px;
}
.titles .divider {
  width: 6px;
  height: 20px;
  background-color: #797777;
}
.titles .title-text {
  max-width: 95px;
  width: 100%;
  height: 20px;
  background-color: #ffffff;
}

.transaction-filtering {
  width: 100%;
  height: 80px;
  background-color: white;
  border-radius: 12px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.transaction-filtering .filtering-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  gap: 10px;
}
.transaction-filtering .filtering-contents .transactions {
  max-width: 132px;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}
.transaction-filtering .filtering-contents .transactions .transaction-title {
  max-width: 103px;
  width: 100%;
  height: 24px;
  background-color: #F8F8F8;
}
.transaction-filtering .filtering-contents .transactions .tansaction-numbers {
  max-width: 132px;
  height: 20px;
  background-color: #F8F8F8;
}
.transaction-filtering .filtering-contents .search-transactions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 10px;
  max-width: 494px;
  width: 100%;
  height: 48px;
  background-color: #F8F8F8;
  border-radius: 8px;
}
.transaction-filtering .filtering-contents .search-transactions .search-placeholder {
  max-width: 300px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .search-transactions .search-icon {
  max-width: 20px;
  width: 100%;
  height: 20px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .transaction-filter-button {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 131px;
  width: 100%;
  height: 44px;
  background-color: #F8F8F8;
  padding: 15px;
  border-radius: 8px;
}
.transaction-filtering .filtering-contents .transaction-filter-button .filter-text {
  max-width: 90px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}
.transaction-filtering .filtering-contents .transaction-filter-button .filter-icon {
  max-width: 30px;
  width: 100%;
  height: 24px;
  background-color: #FFFFFF;
}

.table-contents {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.table-contents .table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.table-contents .table .contents {
  width: 100%;
  height: 68px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 24px 12px;
}
.table-contents .table .contents .content-detail {
  max-width: 200px;
  width: 100%;
  height: 24px;
  background-color: #F8F8F8;
  border-radius: 5px;
}
.table-contents .table .contents .white {
  background-color: #FFFFFF;
}
.table-contents .table .gray {
  background-color: #F8F8F8;
}
.table-contents .table-pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 315px;
  width: 100%;
  height: 45px;
  padding: 0 16px;
  background-color: #F8F8F8;
  align-self: flex-end;
  border-radius: 8px;
}
.table-contents .table-pagination .pagination {
  width: 100%;
  max-width: 70px;
  height: 30px;
  background-color: #FFFFFF;
  border-radius: 5px;
}/*# sourceMappingURL=transaction_page_loader.css.map */