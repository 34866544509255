.cohort-popup {
  margin-left: auto;
  max-width: 600px;
  background-color: white;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  z-index: 100;
  display: block;
  overflow-y: auto;
}
.cohort-popup .close-icon {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-bottom: 15px;
}
.cohort-popup .applicant-info {
  display: grid;
  gap: 40px;
}
.cohort-popup .applicant-info .applicant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cohort-popup .applicant-info .applicant-header h3 {
  font-size: 20px;
  font-weight: 600;
}
.cohort-popup .applicant-info .applicant-header p {
  font-size: 16px;
  color: #9B9B9B;
}
.cohort-popup .applicant-info .applicant-header .name-date {
  display: grid;
  gap: 8px;
}
.cohort-popup .applicant-info .applicant-header .status-action {
  display: flex;
  gap: 7px;
  align-items: center;
}
.cohort-popup .applicant-info .applicant-header .status-action .status, .cohort-popup .applicant-info .applicant-header .status-action .action {
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: #F9F9F9;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  height: 44px;
  border-radius: 8px;
  justify-content: center;
}
.cohort-popup .applicant-info .applicant-header .status-action p, .cohort-popup .applicant-info .applicant-header .status-action h4 {
  font-weight: 400;
}
.cohort-popup .applicant-info .applicant-header .status-action h4 {
  color: #145C9E;
  font-size: 16px;
}
.cohort-popup .cohort-background {
  display: grid;
  gap: 20px;
}
.cohort-popup .cohort-background .cohort-text {
  display: flex;
  align-items: center;
  gap: 40px;
}
.cohort-popup .cohort-background .cohort-text .text-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cohort-popup .cohort-background .cohort-text .text-icon p {
  color: #727272;
}
.cohort-popup .cohort-background .cohort-text h2 {
  font-size: 16px;
  font-weight: 500;
}
.cohort-popup .training-reminder {
  display: flex;
  justify-content: space-between;
  border: 1px solid #F6F6F6;
  border-radius: 12px;
  padding: 12px 14px 12px 14px;
  align-items: center;
}
.cohort-popup .training-reminder .training-payed {
  display: flex;
  gap: 10px;
  align-items: center;
}
.cohort-popup .training-reminder .training-payed p {
  font-weight: 500;
}
.cohort-popup .training-reminder .training-payed h4 {
  font-size: 14px;
  font-weight: 400;
  color: #145C9E;
  background-color: #EFF7FF;
  padding: 6px 16px 6px 16px;
  border-radius: 8px;
}
.cohort-popup .training-reminder .icon-reminder {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #FFECE4;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  color: #F87C47;
}
.cohort-popup .taby {
  margin-top: 30px;
  background-color: #FAFAFA;
  display: flex;
  padding: 12px;
  border-radius: 12px;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}
.cohort-popup .taby .tab-button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #8A8A8A;
  cursor: pointer;
}
.cohort-popup .taby .tab-button.active {
  background-color: #FEEEE7;
  color: #F87C47 !important;
  padding: 16px;
  border-radius: 12px;
}
.cohort-popup .other-info {
  display: grid;
  gap: 40px;
  padding: 10px;
}
.cohort-popup .other-info .personal h1 {
  font-size: 16px;
  font-weight: 500;
}
.cohort-popup .other-info .personal p {
  font-weight: 400;
  color: #B6B6B6;
}
.cohort-popup .other-info .personal .name-phone {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding-top: 15px;
}
.cohort-popup .other-info .personal .name-phone .text-info {
  display: grid;
  gap: 10px;
}

.cohort-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
}/*# sourceMappingURL=cohortapplicant.css.map */