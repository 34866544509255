.job-match-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.job-match-container .top-title {
  display: flex;
  gap: 10px;
}
.job-match-container .top-title span:first-child {
  color: #676767;
}
.job-match-container .job-match-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.job-match-container .job-match-main-container .job-match-top {
  display: flex;
  gap: 10px;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards {
  width: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row {
  display: flex;
  gap: 10px;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row .icon-container {
  background-color: #f2f9ff;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row .icon-container .card-icon {
  color: #145c9e;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row span {
  font-size: 24px;
  font-weight: bold;
}
.job-match-container .job-match-main-container .job-match-top .grid-cards .card .grey-text {
  font-size: 14px;
  color: #999999;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.job-match-container .job-match-main-container .job-match-top .job-match-chart {
  width: 60%;
  background-color: #ffffff;
  border-radius: 12px;
}
.job-match-container .job-match-main-container .job-match-bottom {
  display: flex;
  gap: 10px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .top-row,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .top-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .top-row .view-all,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .top-row .view-all {
  display: flex;
  align-items: center;
  color: #f87c47;
  cursor: pointer;
  gap: 5px;
  font-weight: bold;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table thead tr th,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table thead tr th {
  font-size: 14px;
  color: #808080;
  font-weight: 500;
  padding-inline: 20px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td {
  padding: 5px 10px;
  font-size: 14px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td .type,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td .type {
  background-color: #fff7f4;
  padding: 5px 15px;
  border-radius: 8px;
  color: #f87c47;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td .date,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td .date {
  font-size: 12px;
  color: #919393;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .job-posting-td .company,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .job-posting-td .company {
  font-size: 12px;
  color: #919393;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .application-date-td .time,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .application-date-td .time {
  font-size: 12px;
  color: #919393;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .status-td .status,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .status-td .status {
  background-color: #fff7f4;
  padding: 5px 15px;
  border-radius: 8px;
  color: #f87c47;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr #action,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr #action {
  text-align: center;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr #action .dots,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr #action .dots {
  cursor: pointer;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .applicant-td,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .applicant-td {
  display: flex;
  gap: 10px;
  align-items: center;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .applicant-td #initial,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .applicant-td #initial {
  padding: 10px;
  background-color: #fcf1ec;
  color: #f87c47;
  border-radius: 50%;
  font-size: 12px;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .applicant-td #col,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .applicant-td #col {
  display: flex;
  flex-direction: column;
}
.job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .applicant-td #col .email,
.job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .applicant-td #col .email {
  font-size: 12px;
  color: #919393;
}

@media screen and (max-width: 1350px) {
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td {
    padding: 5px 5px 5px 0px;
  }
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td .email,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td .email {
    display: none;
  }
}
@media screen and (max-width: 1100px) {
  .job-match-container .job-match-main-container .job-match-top {
    flex-direction: column;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards {
    width: 100%;
  }
  .job-match-container .job-match-main-container .job-match-top .job-match-chart {
    width: 100%;
  }
  .job-match-container .job-match-main-container .job-match-bottom {
    flex-direction: column;
  }
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td .email,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td .email {
    display: block;
  }
}
@media screen and (max-width: 850px) {
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .status-td,
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr .status-th,
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table thead tr .status-td,
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table thead tr .status-th,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .status-td,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr .status-th,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table thead tr .status-td,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table thead tr .status-th {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .job-match-container .job-match-main-container .job-match-bottom .application-date-td,
  .job-match-container .job-match-main-container .job-match-bottom .application-date-th {
    display: none;
  }
  .job-match-container .job-match-main-container .job-match-bottom .job-matches,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups {
    padding: 15px;
  }
  .job-match-container .job-match-main-container .job-match-bottom .job-matches .table-container table tbody tr td .email,
  .job-match-container .job-match-main-container .job-match-bottom .recent-signups .table-container table tbody tr td .email {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .job-match-container .job-match-main-container .job-match-top {
    flex-direction: column;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards {
    width: 100%;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row .icon-container {
    padding: 5px 5px;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row .icon-container .card-icon {
    height: 15px;
    width: 20px;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards .card .job-row span {
    font-size: 20px;
  }
  .job-match-container .job-match-main-container .job-match-top .grid-cards .card .grey-text p {
    font-size: 12px;
    text-align: start;
  }
  .job-match-container .job-match-main-container .job-match-top .job-match-chart {
    width: 100%;
  }
}/*# sourceMappingURL=overview.css.map */