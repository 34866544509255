@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Outfit", sans-serif;
  background-color: #fafafa;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: transparent;
  border-radius: 8px;
  padding: 14px;
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

input {
  padding: 11px 26px;
  border: 1px solid #ececec;
  border-radius: 8px;
}

::-moz-placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

::placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

select {
  padding: 11px 26px;
  border: 1px solid #ececec;
  border-radius: 8px;
  background: transparent;
}

::-moz-placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

::placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

textarea {
  padding: 11px 14px;
  color: #979797;
  border-radius: 8px;
  border: 1px solid #ececec;
}

::-moz-placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

::placeholder {
  color: #979797;
  font-family: "Outfit", sans-serif;
}

.no-data {
  color: #145c9e;
  font-size: 20px;
  padding-top: 20px;
}

.message {
  color: #f87c47;
  font-size: 18px;
}

.primary-button {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background-color: #f87c47;
  color: white;
  transition: linear 0.3 linear;
}
.primary-button:hover {
  background-color: #145c9e;
}

.secondary-button {
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background-color: white;
  color: #145c9e;
  transition: linear 0.3 linear;
  border: 1px solid #145c9e;
}
.secondary-button:hover {
  background-color: #145c9e;
  color: white;
}

.loading-container {
  color: #145c9e;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-container .loading {
  font-size: 24px;
}
@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.loading-container .loading-dots {
  display: inline-block;
  font-size: 24px;
}
.loading-container .loading-dot {
  display: inline-block;
  animation: loadingAnimation 1s infinite;
}
.loading-container .loading-dot:nth-child(1) {
  animation-delay: 0.1s;
}
.loading-container .loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-container .loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.input-fields {
  display: flex;
  align-items: center;
  gap: 24px;
}
.input-fields .field {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sidebar {
  position: fixed;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  width: 20%;
  background-color: #145c9e;
  left: 0;
  top: 0;
}
.sidebar .links-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sidebar .links-container .branding {
  text-align: center;
}
.sidebar .links-container .links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sidebar .links-container .links .link {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 12px;
}
.sidebar .links-container .links .link a {
  color: #ffffff;
  font-size: 14px;
}
.sidebar .links-container .links .active {
  background-color: #f87c47;
  padding: 12px;
  border-radius: 8px;
}
.sidebar .logout {
  color: #ffffff;
  padding-top: 450px;
  padding-inline: 30px;
}
.sidebar .logout button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: transparent;
  background: transparent;
  font-size: 16px;
  color: #ffffff;
}

.mobile-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  background-color: #145c9e;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
}
.mobile-sidebar .links-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mobile-sidebar .links-container .links {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mobile-sidebar .links-container .links .link {
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 12px;
}
.mobile-sidebar .links-container .links .link a {
  color: #ffffff;
  font-size: 14px;
}
.mobile-sidebar .links-container .links .active {
  background-color: #f87c47;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
}
.mobile-sidebar .logout {
  color: #ffffff;
  padding-top: 450px;
  padding-inline: 30px;
}
.mobile-sidebar .logout button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border: transparent;
  background: transparent;
  font-size: 16px;
  color: #ffffff;
}

.col-2 {
  width: 80%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}
nav .user-details-popup {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  display: grid;
  justify-content: right;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-inline: 24px;
  padding-top: 60px;
}
nav .user-details-popup .user-profile-details {
  background-color: #ffffff;
  height: 45%;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
nav .user-details-popup .user-profile-details .user-row {
  display: flex;
  gap: 8px;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 16px;
}
nav .user-details-popup .user-profile-details .user-row .col .email {
  color: #5e5d5d;
}
nav .user-details-popup .user-profile-details .user-row .col-1 {
  background-color: #fff7f3;
  border-radius: 50%;
  padding: 15px;
}
nav .user-details-popup .user-profile-details .user-row .col-1 p {
  color: #f87c47;
  font-weight: bold;
}
nav .user-details-popup .user-profile-details .popup-links-container {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
nav .user-details-popup .user-profile-details .popup-links-container .popup-link {
  display: flex;
  gap: 6px;
  align-items: center;
}
nav .user-details-popup .user-profile-details .popup-links-container .popup-link img {
  width: 24px;
}
nav .user-details-popup .user-profile-details .popup-links-container .popup-link button {
  background: transparent;
  font-size: 16px;
}
nav .user-details-popup .user-profile-details .popup-links-container .popup-link button a {
  color: #000;
}
nav .user-details-popup .user-profile-details .popup-links-container .popup-link .signout a {
  color: #f87c47;
}
nav h3 {
  text-transform: capitalize;
}
nav .menu-btn {
  display: none;
  background: transparent;
  font-size: 24px;
  color: #aeaeae;
}
nav .input {
  position: relative;
}
nav .input .icon {
  position: absolute;
  top: 15px;
  left: 8px;
  font-size: 12px;
  color: #979797;
}
nav .logo {
  display: none;
}
nav .profile-section {
  display: flex;
  align-items: center;
  gap: 12px;
}
nav .profile-section .notification {
  border: 1px solid #ececec;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  color: #979797;
  font-size: 30px;
}
nav .profile-section .profile {
  background-color: #fff7f3;
  padding: 15px;
  border-radius: 50%;
}
nav .profile-section .profile p {
  font-weight: bold;
  color: #f87c47;
}
nav .profile-section .user-details button {
  background: transparent;
  font-size: 30px;
}

.contents-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contents-container .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-contents table {
  width: 100%;
  border-collapse: collapse;
}
.table-contents table .mobile-layout {
  display: none;
}
.table-contents table thead {
  background-color: #fafafa;
}
.table-contents table th,
.table-contents table td {
  text-align: left;
  padding: 16px;
}
.table-contents table th {
  font-weight: 200;
  color: #a5a3a9;
}
.table-contents table td {
  color: #666666;
  font-weight: 300;
  font-size: 14px;
}
.table-contents table td span {
  background-color: #fff7f3;
  color: #f87c47;
  font-weight: bold;
  padding: 8px;
  border-radius: 50%;
}
.table-contents table td button {
  background: transparent;
}
.table-contents table tbody tr:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 15px 30px;
}
.pagination button {
  background: transparent;
  border: 0.5px solid #a5a3a9;
  padding: 8px 18px;
  border-radius: 0;
  color: #a5a3a9;
}
.pagination .active {
  background-color: #145c9e;
}

.popup {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  display: grid;
  place-items: center;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }
  .col-2 {
    width: 100%;
  }
  nav .menu-btn {
    display: block;
  }
}
@media screen and (max-width: 540px) {
  nav {
    padding: 10px;
  }
  nav h3 {
    display: none;
  }
  nav .input {
    display: none;
  }
  nav .logo {
    display: block;
  }
  .contents-container {
    margin: 15px 0;
  }
  .contents-container .top {
    flex-direction: column;
    gap: 24px;
    align-items: start;
  }
  .table-contents table th {
    display: none;
  }
  .table-contents table td {
    text-align: left;
    padding: 24px 10px;
    font-size: 12px;
  }
  .table-contents table .hidden-table-col {
    display: none;
  }
  .table-contents table .mobile-layout {
    display: flex;
    flex-direction: column;
  }
  .table-contents table .mobile-layout .user-details {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-right: 12px;
  }
  .table-contents table .mobile-layout .user-details .email {
    font-size: 12px;
  }
  .table-contents .pagination .pagination-btn {
    display: none;
  }
  .popup {
    padding-inline: 12px;
  }
  .input-fields {
    flex-direction: column;
  }
  .input-fields .field {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.custom-select-input {
  position: relative;
}
.custom-select-input .options-container {
  background-color: whitesmoke;
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 4;
}
.custom-select-input .options {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
}
.custom-select-input .options .option {
  padding: 6px;
  border-bottom: 1px solid rgb(231, 231, 231);
}
.custom-select-input .options .option:last-child {
  border-bottom: none;
}
.custom-select-input .options .option:hover {
  background-color: #f87c47;
  border-color: transparent;
}
.custom-select-input .options .option:first-child {
  color: gray;
}
.custom-select-input .options .option:first-child:hover {
  background-color: transparent;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 12px;
}
.tabs .tab {
  display: flex;
  align-content: center;
  gap: 6px;
}

.loader {
  background-color: whitesmoke;
  animation: shimmer 2s ease-in-out infinite;
  background: linear-gradient(to right, #f0f0f0 8%, #e0e0e0 18%, #f0f0f0 33%);
  background-size: 800px 100px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.no-results-found {
  padding: 24px;
  background-color: whitesmoke;
  margin-top: 12px;
  width: 100%;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  border-radius: 12px;
}
.no-results-found button {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}

.notification {
  position: fixed;
  top: 20px;
  /* Changed from bottom to top for header positioning */
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s, transform 0.5s;
}

.notification.visible {
  opacity: 1;
  transform: translateY(0);
}

.search-field {
  position: relative;
}
.search-field .search-container {
  position: absolute;
  top: 68px;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 10;
  padding: 12px;
  border-radius: 12px;
  display: grid;
  gap: 12px;
}
.search-field .search-container .search-input {
  width: 100%;
  position: sticky;
  top: 0;
}
.search-field .search-container .search-input input {
  width: 100%;
}
.search-field .search-container .result {
  color: gray;
  padding: 12px;
  border-bottom: 1px solid whitesmoke;
}

.loading-requirements {
  padding: 24px;
  background-color: rgba(248, 124, 71, 0.2470588235);
  text-align: center;
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 12px;
  justify-self: center;
  justify-items: center;
}

.loading-icon {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-items {
  display: flex;
  align-items: center;
  gap: 12px;
}/*# sourceMappingURL=main.css.map */