$primary_color: #145c9e;
$light_blue: #dceeff;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

// borders of the calendar
.fc-theme-standard .fc-scrollgrid {
    border: 1px solid $light_orange;
}

// month and year title
.fc .fc-toolbar-title {
    font-size: 20px;
}

// calendar filter buttons
.fc .fc-button-primary {
    text-transform: capitalize;
    background-color: $primary_color;
    opacity: 1.1;
    border: transparent;
}

.fc .fc-button:disabled {
    text-transform: capitalize;
    background-color: $primary_color;
    opacity: 1.1;
    border: transparent;
}

// events background
.fc-event {
    background-color: $light_blue;
    border: transparent;
    padding-inline: 10px;
    width: fit-content;
}

// events container
.fc .fc-daygrid-event {
    margin-top: 10px;
    z-index: 6;

    .event-title {
        color: $primary_color;
    }
}

// events title
.fc-h-event .fc-event-main {
    color: $primary_color;
    font-weight: 400;
}

.fc-daygrid-event {
    white-space: wrap;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
    margin-right: 10px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
    margin-left: 10px;
}

.leaves-schedule {
    display: flex;
    gap: 24px;

    .table-content {
        width: 70%;

        table {
            thead {
                background-color: #fafafa;
            }

            th {
                font-weight: 200;
                color: #a5a3a9;
                border: 1px solid $light_orange;
            }

            td {
                color: #666565;
                font-weight: 300;
                font-size: 14px;
                border: 1px solid $light_orange;
            }
        }
    }

    .upcoming-leaves {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        .title {
            display: flex;
            flex-direction: column;
            gap: calc($medium_gap / 2);

            p {
                color: $gray_color;
            }
        }

        .leave-details {
            display: flex;
            flex-direction: column;
            gap: $medium_gap;

            .leave {
                padding: 16px;
                display: flex;
                flex-direction: column;
                gap: calc($medium_gap / 2);

                h4 {
                    font-size: 16px;
                    font-weight: 400;
                }

                p {
                    font-size: 14px;
                    color: $gray_color;
                }
            }

            .leave:nth-child(odd) {
                background-color: #fffcf6;
                border-left: 2px solid #ffe2d5;
            }

            .leave:nth-child(even) {
                background-color: #f7fbff;
                border-left: 2px solid #dff0ff;
            }
        }
    }
}

// .fc-day {
//     background-color: lightblue;
// }

@media screen and (max-width: 768px) {
    .leaves-schedule {
        flex-direction: column;

        .table-content {
            width: 100%;
        }
    }
}

@media screen and (max-width: 540px) {
    .fc-event {
        visibility: hidden;
    }
    .fc .fc-daygrid-event-harness {
        background-color: $light_orange;
    }
}

