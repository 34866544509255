.reset-password-section {
  display: grid;
  place-content: center;
  height: 100vh;
}
.reset-password-section .requesting-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes loadingAnimation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.reset-password-section .requesting-container .requesting-dots {
  display: inline-block;
}
.reset-password-section .requesting-container .requesting-dot {
  display: inline-block;
  animation: loadingAnimation 1s infinite;
}
.reset-password-section .requesting-container .requesting-dot:nth-child(1) {
  animation-delay: 0.1s;
}
.reset-password-section .requesting-container .requesting-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.reset-password-section .requesting-container .requesting-dot:nth-child(3) {
  animation-delay: 0.3s;
}
.reset-password-section .col {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.reset-password-section .col h1 {
  color: #145c9e;
}
.reset-password-section .col .title {
  color: #f87c47;
}
.reset-password-section .col .subtitle {
  font-weight: bold;
  color: #000;
}
.reset-password-section .col p {
  color: #979797;
}
.reset-password-section .col form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.reset-password-section .col form .input {
  position: relative;
}
.reset-password-section .col form .input .icon {
  position: absolute;
  left: 8px;
  top: 11px;
  width: 24px;
  height: 24px;
  color: #979797;
}
.reset-password-section .col form .input input {
  width: 100%;
}
.reset-password-section .col form .message {
  color: #f87c47;
}
.reset-password-section .col form button {
  background-color: #f87c47;
  padding: 12px 24px;
  color: #ffffff;
}/*# sourceMappingURL=reset-password.css.map */