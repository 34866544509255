$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

.login-section {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    padding-inline: 80px;
    gap: 80px;

    img {
        width: 100%;
        height: 90vh;
        max-width: 600px;
        object-fit: cover;
        border-radius: 12px;

    }


    .col {
        display: flex;
        flex-direction: column;
        gap: $medium_gap;
        justify-content: center;
        max-width: 600px;

        h1 {
            color: $primary_color;
        }

        .message {
            color: $secondary_color;
        }

        span {
            color: $secondary_color;
        }

        p {
            color: $gray_color;
            font-size: 18px;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: $big_gap;

            .login-form {
                display: flex;
                flex-direction: column;
                gap: $big_gap;

                .input {
                    border: 1px solid #979797;
                    display: flex;
                    padding: 4px 24px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;

                    .icon {

                        color: $gray_color;
                        font-size: 20px;
                    }

                    input {
                        width: 100%;
                        outline: none;
                        background-color: #fafafa;
                        border: none;
                        font-size: 16px;

                    }
                }

                .forgot-password {
                    text-align: right;

                    button {
                        background: transparent;
                        border: transparent;
                        color: $secondary_color;
                    }
                }

                button {
                    padding: 11px 26px;
                    background-color: $secondary_color;
                    color: $white_color;
                }

                .login-btn {
                    padding: 16px 26px;
                    width: 100%;
                    font-size: 16px;



                }

                .login-btn:hover {
                    background-color: $primary_color ;
                }
            }

            .reset-password {
                display: flex;
                flex-direction: column;
                gap: 24px;

                p {
                    width: 70%;
                }

                span {
                    color: #000;
                    font-weight: bold;
                }

                .input {
                    position: relative;

                    .icon {
                        position: absolute;
                        left: 8px;
                        top: 11px;
                        width: 24px;
                        height: 24px;
                        color: $gray_color;
                    }

                    input {
                        width: 100%;

                    }
                }

                .verify-email {
                    background-color: $secondary_color;
                    padding: 11px 26px;

                    .verify-email-link {
                        color: $white_color;
                    }
                }
            }
        }

        .social-media {
            .google-btn {

                background-color: $secondary_color;
                padding: 16px 28px;
                color: white;
                display: flex;
                gap: 12px;
                align-items: center;
                font-size: 16px;
                width: 100%;
                display: flex;
                justify-content: center;



            }

            .google-btn:hover {
                background-color: $primary_color ;

            }
        }
    }
}

@media screen and (max-width: 1152px) {
    .login-section {
        padding-inline: 20px;
        gap: 24px;
    }
}

@media screen and (max-width:1040px) {
    .login-section {
        padding-inline: 20px;


        img {
            display: none;
        }

        .col {
            width: 100%;
        }
    }

}