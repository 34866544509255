@import "variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

body {
  background-color: $background_color;
}

.content {
  min-height: 100vh;
}

.page {
  background-color: $background_color;
}

.content {
  padding: 1rem;
}

.main-div{
  display: flex;
  gap: 1rem;

}


.menu-title {
  margin-block: 1rem;
  display: flex;
  gap: 13px;
  align-items: center;

  h2 {
    font-weight: 500;
    font-size: 18px;
  }

  .count {
    background-color: $secondary_background_color;
    width: 37px;
    height: 31px;
    padding: 6px 10px;
    border-radius: $border_radius;

    font-family: $secondary_font_family;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .menu-item {
    height: 77px;
    border-radius: 24px;
    min-width: 250px;
    display: flex;
    background-color: $secondary_background_color;
    align-items: center;
    gap: 17px;
    padding: $padding;

    .menu-image {
      width: 40px;

      img {
        max-width: 100%;
        height: auto;
        border-radius: $border_radius;
      }
    }



    .menu-item-description {
      color: #000000;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .last-row {
        display: flex;
        gap: 24px;
        align-items: center;

        .price-div {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .price {
          font-weight: 500;
        }

        .served,
        span {
          color: #676767;
        }

        .currency {
          font-size: 12px;
        }
      }
    }
  }
}


.order-container {
  width: 324px;
  height: 686px;
  border-radius: $border_radius;
  display: flex;
  flex-direction: column;
  padding: $padding;

  .order-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .popup-content {
      background-color: $secondary_background_color;
      padding: $padding;
      padding-top: 19px;

      position: relative;
      max-width: 744px;
      height: 480px;
      width: 100%;
      display: grid;
      grid-template-columns: 40% 60%;

      h2 {
        font-weight: 600;
        font-size: $font_size;
        padding-bottom: 33px;
      }

      .order-details-div {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .item {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .price {
          display: flex;
          gap: 8px;
          align-items: center;

          p {
            font-weight: 500;
          }

          span {
            font-size: 12px;
          }
        }
      }

      .popup-total {
        display: flex;
        align-items: center;

        padding-bottom: 20px;
        position: absolute;
        bottom: 0;
        gap: 2rem;

        h3 {
          font-weight: 500;
          font-size: $font_size;
        }
      }

      .second-row {
        display: flex;
        flex-direction: column;


        .anonymous-user-form {
          display: flex;
          gap: 1rem;
          padding-bottom: 20px;

          input {

            background-color: #f3f3f3;
            padding: 15px 15px;
            border-radius: 12px;
            border: none;
            outline: none;
            font-family: $font_family;
            max-width: 180px;
          }
        }

        .first-line {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .close-icon {
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin-bottom: 33px;
          }
        }

        .department {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .department-title {
            padding-bottom: 0;
          }

          .departments-div {
            width: 377px;
            height: 190px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            .department-item {
              width: 182px;
              height: 46px;
              background-color: #f5f5f5;
              padding: 13px 16px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.active {
                border: 1px solid #fde5b6;
              }
            }
          }
        }

        .buttons {
          display: flex;
          gap: 16px;
          position: absolute;
          bottom: 0;
          padding-bottom: 20px;
          right: 60px;

          .cancel,
          .send {
            width: 183px;
            height: 44px;
            display: flex;
            border-radius: 8px;
            gap: 8px;
            align-items: center;
            justify-content: center;

            padding: 12px 54px;
            cursor: pointer;
          }

          .cancel {
            background-color: #e5e5e5;
            color: #767676;
            padding: 12px 54px;
          }

          .send {
            background-color: $primary-color;
            color: $secondary_background_color;
            padding: 12px 34px;
          }
        }
      }
    }
  }

  .success-div {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .success-content {
      background-color: $secondary_background_color;
      padding: $padding;

      position: relative;
      max-width: 611px;
      height: 406px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 77px;

      .first-line {
        h3 {
          position: absolute;
          top: 18px;
          left: 22px;
        }

        img {
          position: absolute;
          top: 18px;
          right: 22px;
          cursor: pointer;
        }
      }

      .content {
        flex-direction: column;
        width: 422px;
        height: 120px;
        gap: 12px;
        text-align: center;
        display: flex;
        align-items: center;

        img {
          width: 136px;
          height: 80px;
        }

        h2 {
          font-weight: 600;
          font-size: 20px;
          color: $primary-color;
        }

        p {
          color: #696969;
        }
      }

      .button {
        background-color: $primary-color;
        width: 201px;
        height: 48px;
        position: absolute;
        bottom: 0;
        display: flex;
        gap: 10px;
        padding: 12px 34px;
        border-radius: 8px;
        margin-bottom: 30px;

        a {
          color: $secondary_background_color;
        }
      }
    }
  }
}

.order-details {
  background-color: $secondary_background_color;
  border-radius: $border_radius;
  padding: $padding;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;

    h2 {
      font-weight: 500;
      font-size: 18px;
    }

    .date {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #8f8f8f;
    }
  }

  .orders {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .order-item {
    width: 282px;
    height: 90px;
    border-radius: $border_radius;
    padding: 12px 16px;
    gap: 7px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;

    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .price {
      display: flex;
      justify-content: space-between;

      .cost {
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  .quantity {
    display: flex;

    justify-content: center;
    background-color: $secondary_background_color;
    width: 86px;
    height: 36px;
    padding: 8px;
    gap: 10px;
    border-radius: $border_radius;
  }

  .total {
    display: flex;
    flex-direction: column;
    gap: 9px;
    position: absolute;
    bottom: 30px;

    h3 {
      font-size: $font_size;
      font-weight: 400;
      color: #8d8d8d;
    }

    .price {
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 24px;
      padding-bottom: 24px;

      p:nth-child(2) {
        font-size: $font_size;
        font-weight: 400;
      }
    }

    .button {
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 271px;
      height: 48px;
      border-radius: $border_radius;
      padding: 12px 10px;

      a {
        text-decoration: none;
        color: $secondary_background_color;
      }
    }
  }
}

.view-order {
  display: none;
}


@media screen and (max-width: 1200px) {

  .category {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

}

@media screen and (max-width: 968px) {
  .header {
    position: absolute;
    top: 0;
    left: 0;

    .nav {

      .menu-btn,
      .history-btn {
        display: none;
      }
    }

    .search-bar {
      display: none;
    }

    .notifications-div {
      display: flex;
      gap: 15px;
    }

    .mobile-menu {
      display: flex;
      cursor: pointer;
      border-radius: $border_radius;

      .menu-div {
        .dropdown {
          position: absolute;
          padding: $padding;
          left: 0;

          z-index: 1;
          background-color: $secondary_background_color;
          width: 100vw;

          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
              height: 48px;

              .menu {
                background-color: #282c7d;
                color: #fff;
              }

              .history {
                background-color: #f6f6f6;
                color: #6c6a6a;
              }

              a {
                width: 100%;
                padding: $padding;
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
              }
            }
          }
        }
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main-div {
      display: block;
      flex: 1;
    }

    .view-order {
      display: flex;
      padding: 20px 120px;
      justify-content: center;

      a {
        color: #fff;
        background-color: #1c274c;
        min-width: 377px;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border_radius;
      }
    }

    .first-row {
      width: 100%;

      .category {
        margin-bottom: 30px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        justify-content: center;
        padding: $padding;
      }
    }
  }

  .main-div {
    .order-details {
      display: none;
    }
  }
}

@media screen and (max-width: 530px) {
  main {
    .first-row {
      .menu {
        flex-direction: column;
      }

      .category {
        gap: 10px;

        .category-item.active {
          gap: 1rem;
          width: 130px;
        }

        .category-item {
          gap: 1rem;

          .image {
            width: 42px;
          }
        }
      }
    }
  }
}