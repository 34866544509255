$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$secondary_orange: #fff4d9;
$gray_color: #979797;
$light_gray: #fafafa;
$primary_gray: #8a8a8a;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.actions-popup-menu {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-content: center;
    justify-content: right;
    height: 100vh;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .actions-popup {
        background-color: $white_color;
        display: flex;
        flex-direction: column;
        gap: $big_gap;
        padding: 32px;
        margin-right: 48px;
        border-radius: $border_radius;

        button {
            background: transparent;
            display: flex;
            align-items: center;
            gap: 6px;
        }
    }
}

.leave-details-popup {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-content: center;
    height: 100vh;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    small {
        color: gray;
    }

    .popup-content {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: white;
        padding: 24px;
        width: 600px;
        animation: ease showLeavePopup 0.3s forwards;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title-status,
        .leave-info,
        .employee-details,
        .leave-reason {
            border: 1px solid whitesmoke;
            padding: 24px;
            border-radius: 6px;
        }

        .title-status {
            display: flex;
            align-items: center;
            justify-content: space-between;


            .status {
                background-color: #145c9e;
                color: white;
                padding: 6px 12px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                width: fit-content;
                gap: 12px;
                border-radius: 6px;

                .dot {
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: white;

                }
            }
        }

        .leave-info {
            display: grid;
            height: fit-content;
            gap: 16px;

            .info {
                display: grid;
                height: fit-content;
                grid-template-columns: 1fr 1fr;

                .label {
                    display: flex;
                    align-content: center;
                    gap: 6px;
                }
            }
        }


    }

    @keyframes showLeavePopup {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }
}

.leave-requests-data {
    display: flex;
    flex-direction: column;
    gap: $big_gap;

    .leave-request {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        gap: $big_gap;
        background-color: $light_gray;
        padding: 8px 16px;
        border-radius: $border_radius;

        .col-profile {
            display: flex;
            align-items: center;
            gap: $big_gap;

            .user-names {
                background-color: $light_orange;
                padding: 24px;
                border-radius: 50%;

                p {
                    font-weight: bold;
                    font-size: 14px;
                    color: $secondary_color;
                }
            }

            img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                object-fit: cover;
            }

            h4 {
                font-size: 14px;
            }

            p {
                color: $gray_color;
                font-size: 12px;
            }

            .user-details {
                display: flex;
                flex-direction: column;
                gap: calc($medium_gap / 2);
            }
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: $medium_gap;

            .title {
                font-size: 14px;
                color: $gray_color;
                font-weight: 400;
            }

            p {
                font-size: 12px;
            }
        }

        .col-btns {
            display: flex;
            gap: calc($medium_gap / 2);

            .close-btn,
            .actions-btn {
                padding: 12px;
            }

            .close-btn {
                background-color: $secondary_orange;
                color: #ffb708;
                font-weight: bold;
                font-size: 18px;
            }

            .actions-btn {
                background-color: #f3f3f3;
            }

            .accept-btn {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 8px 24px;
                background-color: $secondary_color;
                color: $white_color;
            }
        }
    }
}