@import '../variables';

.tox .tox-editor-container .tox-edit-area__iframe {
    direction: ltr !important;
}

.new-blog-page {
    display: grid;
    height: fit-content;
    gap: 2rem;

    .form {
        display: flex;
        align-items: start;
        gap: 1rem;
        flex-wrap: wrap;

        .main-content {
            flex: 1 1 400px;
            background-color: white;
            padding: 1rem;
            border-radius: 0.5rem;
            display: grid;
            height: fit-content;
            gap: 2rem;
        }

        .extra-content {
            flex: 1 1 300px;
            max-width: 300px;
            padding: 1rem;
            background-color: white;
            border-radius: 0.5rem;

            position: relative;

            display: grid;
            height: fit-content;
            gap: 2rem;

            .actions {
                display: grid;
                gap: 0.5rem;
                height: fit-content;

                button {
                    width: 100%;
                    padding: 1rem;
                }
            }
        }

        .input {
            display: grid;
            height: fit-content;
            gap: 0.5rem;
        }
    }
}

.author-list,
.department-list {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;


    >:nth-child(even) {
        background-color: lighten($color: $primary-color, $amount: 32);
        color: $primary-color;
    }
}

.author,
.department {
    background-color: #E8F3FD;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    position: relative;
    color: #145C9E;

    .remove-item {
        position: absolute;
        top: 0;
        right: 0;
        background-color: tomato;
        border-radius: 50%;
        color: white;
        height: 1rem;
        width: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}


.categories-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;

    .category-item {
        display: flex;
        background-color: #E8F3FD;
        padding: 0.5rem;
        border-radius: 0.5rem;
        text-wrap: nowrap;
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #145C9E;
        font-size: 10px;
    }
}

.tags-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;

    .tag {
        background-color: #E8F3FD;
        color: #145C9E;
        padding: 0.5rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        text-wrap: nowrap;
        gap: 2rem;
    }
}

.remove-item {
    cursor: pointer;
}

.select-item {
    background-color: #E8F3FD;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
}

.suggestions {
    display: grid;
    gap: 0.3rem;
    height: fit-content;

    .suggestion {
        background-color: #E8F3FD;
        padding: 0.5rem;
        border-radius: 0.5rem;
        text-align: left;
        cursor: pointer;
        width: 100%;
        text-wrap: nowrap;
    }
}

.featured-image-placeholder {
    background-color: lighten($color: $primary-color, $amount: 30);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    min-height: 180px;
    padding: 0.5rem;
    border-radius: 0.5rem;

    img {
        width: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
    }
}

.error-message {
    background-color: lighten($color: tomato, $amount: 20);
    padding: 1rem;
    border-radius: 0.5rem;
}

.success-message {
    background-color: lighten($color: rgb(127, 188, 160), $amount: 20);
    padding: 1rem;
    border-radius: 0.5rem;
}

.input {
    .close-button {
        display: none;
    }
}

.popup-selection {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;

    .author-list,
    .department-list {
        width: 100%;

    }

    .suggestions {
        width: 100%;
    }

    .close-button {
        display: block;
    }
}

.card-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
}