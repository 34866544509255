.jobs-page {
    display: grid;
    height: fit-content;
    gap: 12px;


    .jobs-list {
        display: grid;
        height: fit-content;
        gap: 12px;

        .list {
            padding: 24px;
            border-radius: 12px;
            background-color: white;
            display: grid;
            height: fit-content;
            gap: 12px;
        }
    }
}

.numbers {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 24px;
    background-color: white;
    padding: 12px;
    border-radius: 12px;

    .number {
        flex: 1 1 100px;
        background-color: whitesmoke;
        padding: 24px;
        border-radius: 12px;
        max-width: 300px;

        display: flex;
        align-content: baseline;
        gap: 12px;
        color: #282C7D;

        .icon {
            height: 48px;
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            background-color: white;
        }

        p {
            font-weight: lighter;
            color: black;
        }
    }
}

.search-and-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: white;
    padding: 24px;
    border-radius: 12px;

    .total {
        display: grid;
        gap: 6px;
        height: fit-content;

        small {
            display: flex;
            align-items: center;
            gap: 6px;
            color: gray;

            .figure {
                color: #F87C47;
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        gap: 32px;

        .filter {
            display: flex;
            align-items: center;
            gap: 12px;
            color: #807F7F;
            border: 1px solid #F1F1F1;
            padding: 14px 16px;
            border-radius: 12px;
        }
    }
}

.filters {
    position: relative;

    .filter-popup {
        position: absolute;
        width: 100%;
        top: 0;
        background-color: whitesmoke;
        background: #FFFFFF;
        box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        padding: 14px;
        display: grid;
        height: fit-content;
        gap: 12px;

        .filter-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: #F87C47;
            }
        }
    }
}