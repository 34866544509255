.dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 24px;
    border-bottom: 1px solid #e8e8e8;
    position: sticky;
    top: 0;

    .open-icon {
        display: none;
    }

    .profile-notification {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        color: gray;

        .notification {
            position: relative;

            .dot {
                background-color: red;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .profile {
            display: flex;
            align-items: center;

            .profile-image {
                height: 52px;
                width: 52px;
                border-radius: 50%;
                border: 1px solid #F1F1F1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.search-input {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    border: 1.5px solid #F1F1F1;
    padding-left: 16px;
    background-color: white;

    input {
        padding: 16px 16px 16px 0;
        border: 0;
        width: 400px;
        outline: none;
        background-color: transparent;
    }
}


@media screen and (max-width: 1080px) {
    .dashboard-header {
        .search-input {
            display: none;
        }

        .mobile-search {
            display: flex;
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard-header {
        flex-direction: row-reverse;

        .open-icon {
            display: block;
        }

        .profile-notification {
            flex-direction: row-reverse;

            .profile {
                flex-direction: row-reverse;
            }
        }
    }

}