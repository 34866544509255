@import 'variables.scss';

.category{
    display: flex;
    background-color: $secondary_background_color;
    align-items: center;
    justify-content: space-between;
    border-radius: $border_radius;
    height: auto;
    max-width: 1032px;
    flex-wrap: wrap;
    flex: 1;
    padding: $padding;
    gap: 24px;

    .category-item{
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 12px 16px;
        width: 122px;
        height: 76px;

        &.active{
            background-color: #F8F8FF;
            padding: 12px 14px;
            width: 146px;
            height: 76px;
            border-radius: 24px;

            .image{
                img{
                    height: 52px;
                }
            }
            


            a{
                color: #282C7D;
                font-weight: 500;
            }
        }
        .image{
            width: 52px;
            height: 52px;
            

            img{
                border-radius: $border_radius;
                max-width: 100%;
                max-height: 52px;
            }

            
        }

        a{
            color: #888A8B
        }
    }

}