.leaves-overview-loader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 10px;

  .overviews {
    // width: 100%;
    display: flex;
    // justify-content: space-between;
    gap: 16px;
    // display: grid;
    // grid-template-columns: 40% 58%;
    // gap: 24px;
    // width: 100vw;

    .cards {
      // width: 750px;
      width: 40%;
      gap: 10px;

      display: grid;
      grid-template-columns: 1fr 1fr;

      .card {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        background-color: #ffff;
        border-radius: 12px;
        padding: 24px;

        .icon {
          background-color: #f9f9f9;
          max-width: 48px;
          width: 100%;
          height: 48px;
          border-radius: 8px;
        }

        .title-text {
          display: flex;
          flex-direction: column;
          gap: 14px;
          width: 100%;

          .title {
            background-color: #f9f9f9;
            max-width: 188px;
            width: 100%;
            height: 26px;
            border-radius: 4px;
          }

          .text-midle {
            background-color: #f9f9f9;
            max-width: 74px;
            width: 100%;
            height: 32px;
            border-radius: 4px;
          }

          .text-bottom {
            background-color: #f9f9f9;
            max-width: 160px;
            width: 100%;
            height: 21px;
            border-radius: 4px;
          }
        }
      }
    }

    .charts {
      width: 60%;
      padding: 24px;
      background-color: #ffff;
      display: flex;
      flex-direction: column;
      gap: 32px;
      border-radius: 16px;

      .chart-title {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        .title-text-chart {
          background-color: #f9f9f9;
          max-width: 188px;
          width: 100%;
          height: 26px;
          border-radius: 4px;
        }

        .button-chart {
          background-color: #f9f9f9;
          max-width: 88px;
          width: 100%;
          height: 22px;
        }
      }

      .chart-box-legend {
        display: flex;
        gap: 16px;
        // justify-content: space-between;

        .chart-boxes {
          display: flex;
          flex-direction: column;
          gap: 13px;

          .chart-box {
            background-color: #f9f9f9;
            width: 32px;
            height: 11px;
            border-radius: 9px;
          }
        }

        .legends {
          display: flex;
          gap: 16px;
          width: 100%;

          .legend {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 13px;
            width: 100%;

            .big {
              background-color: #f9f9f9;
              width: 18px;
              height: 211px;
              border-radius: 4px;
            }

            .small {
              background-color: #f9f9f9;
              width: 18px;
              height: 106px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  .contents {
    width: 100%;
    display: flex;
    gap: 24px;

    .left {
      width: 60%;
      background-color: #ffff;
      padding: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .content-title {
        display: flex;
        justify-content: space-between;

        .title-text {
          width: 65%;
          height: 31px;
          background-color: #f9f9f9;
        }

        .button {
          width: 30%;
          height: 31px;
          background-color: #f9f9f9;
        }
      }

      .content-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content-text {
          display: flex;
          gap: 24px;
          width: 100%;
          height: 67px;
          background-color: #ffff;
          padding: 13px 10px 25px 10px;

          .text-field {
            background-color: #f9f9f9;
            width: 100%;
            max-width: 250px;
            height: 42px;
            border-radius: 8px;
          }
        }
      }
    }

    .right {
      width: 40%;
      background-color: #ffff;
      padding: 24px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .content-title {
        display: flex;
        justify-content: space-between;

        .title-text {
          width: 60%;
          height: 31px;
          background-color: #f9f9f9;
        }

        .button {
          width: 30%;
          height: 31px;
          background-color: #f9f9f9;
        }
      }

      .content-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content-text {
          display: flex;
          gap: 24px;
          width: 100%;
          height: 67px;
          background-color: #ffff;
          padding: 13px 10px 25px 10px;

          .text-field {
            background-color: #f9f9f9;
            width: 100%;
            max-width: 250px;
            height: 42px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: flex;
  }
  .leaves-overview-loader {
    .overviews {
      flex-direction: column;

      .cards {
        width: 100%;
      }

      .charts {
        width: 100%;
      }
    }

    .contents {
      flex-direction: column;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 870px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  #last-chart-bar,
  #second-to-last-chart-bar {
    display: none;
  }
}

@media screen and (max-width: 525px) {
  #fifth-chart-bar,
  #sixth-chart-bar {
    display: none;
  }
}

@media screen and (max-width: 390px) {
  #fourth-chart-bar {
    display: none;
  }
}
