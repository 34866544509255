$primary_color: #145c9e;
$secondary_color: #f87c47;
$primary_green: #01a653;
$light_green: #dbf2e7;
$white_color: #ffffff;
$light_orange: #fff7f3;
$gray_color: #979797;
$light_gray: #fafafa;

// gap
$big_gap: 24px;
$medium_gap: 12px;

// border radius
$border_radius: 8px;

.projects {
  .popup {
    place-content: center;

    .edit-project {
      background-color: $white_color;
      padding: 24px;
      border-radius: $border_radius;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $big_gap;

      .title {
        display: flex;
        justify-content: space-between;

        button {
          background: transparent;
          font-size: 24px;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        gap: $big_gap;

        .input-fields {
          display: flex;
          flex-wrap: wrap;
          align-items: start;

          .first-field-col {
            display: flex;
            flex-direction: column;
            gap: $medium_gap;
          }
        }

        label {
          font-size: 14px;
        }

        input,
        select {
          width: 300px;
          color: $gray_color;
        }

        textarea {
          resize: none;
          height: 235px;
          width: 100%;
        }

        input {
          padding: 16px 8px;
        }

        select {
          padding: 16px 8px;
        }

        .radio-inputs {
          display: flex;
          gap: $big_gap;

          .radio-input-field {
            display: flex;
            gap: 6px;
          }
        }

        .btns {
          display: flex;
          gap: 24px;
          justify-content: right;

          button {
            padding: 16px;
          }

          .cancel-btn {
            border: 1px solid #ebebeb;
            background: transparent;
            color: $gray_color;
          }

          .add-btn {
            background-color: $secondary_color;
            color: $white_color;
          }
        }
      }
    }
  }

  .project-details-popup {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    display: grid;
    place-items: center;
    justify-content: right;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .project-details {
      background-color: $white_color;
      padding: 30px;
      border-radius: $border_radius;
      margin-right: 30px;

      .project-row {
        display: flex;
        align-items: center;
        gap: $medium_gap;

        .icon {
          font-size: 20px;
        }

        .project-details-link {
          color: #000;
        }
      }
    }
  }

  .underline {
    background-color: #f1f1f1;
    width: 100%;
    height: 1px;
  }

  .col-2 {
    .contents-container {
      .title-contents {
        .top {
          .col-title {
            p {
              color: $gray_color;
            }

            button {
              background-color: $secondary_color;
              padding: 10px 24px;
              color: $white_color;
            }
          }
        }

        .input-fields {
          padding-top: 10px;

          .input {
            position: relative;

            input {
              padding-left: 15px;
              padding-right: 60px;
            }

            .icon {
              position: absolute;
              left: 220px;
              top: 11px;
              color: $gray_color;
            }
          }
        }
        .add-project-link {
          background-color: #f87c47;
          display: flex;
          align-items: center;
          gap: 6px;
          color: white;
          border-radius: 8px;
          padding: 10px 20px;
        }
      }
    }
  }
}

.progress {
  color: $primary_color !important;
  border-radius: 8px;
}

.completed {
  color: $primary_green !important;
  border-radius: 8px;
}

.canceled {
  color: red !important;
  border-radius: 8px;
}

.pending {
  color: $secondary_color !important;
  border-radius: 8px;
}

.hidden {
  display: none;
}

.additional {
  position: absolute;
  left: 115px;
  top: 40px;
  font-weight: 600;
}

.no-project {
  margin-top: 20px;
  color: red;
  font-size: 24px;
  text-align: center;
}


.new-project-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

  .form {
    padding: 24px;
    background-color: white;
    max-width: 600px;
    display: grid;
    height: fit-content;
    gap: 12px;
    border-radius: 12px;
  }

}


form {
  display: grid;
  height: fit-content;
  gap: 12px;

  .half {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;

    .input {
      flex: 1 1 200px;
    }
  }

  .form-buttons {
    display: flex;
    align-items: stretch;
    gap: 12px;
  }
}

input,
select,
textarea {
  width: 100%;
  padding: 14px;
  font-size: inherit;
  color: inherit;
}

@media screen and (max-width: 540px) {
  .projects {
    .popup {
      .edit-project {
        height: 100svh;
        overflow-y: scroll;

        form {
          .btns {
            justify-content: left;
          }
        }
      }
    }
  }
}