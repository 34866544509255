.recent-blogs-page .recent-blogs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.recent-blogs-page .recent-blogs .blog-card {
  display: flex;
  align-items: stretch;
  gap: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}
.recent-blogs-page .recent-blogs .blog-card img {
  width: 150px;
  border-radius: 0.5rem;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.recent-blogs-page .recent-blogs .blog-info {
  display: grid;
  height: -moz-fit-content;
  height: fit-content;
  gap: 0.5rem;
}
.recent-blogs-page .recent-blogs .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.recent-blogs-page .recent-blogs .actions .action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 12px;
  cursor: pointer;
}
.recent-blogs-page .recent-blogs .actions .delete {
  color: orangered;
}
.recent-blogs-page .recent-blogs .actions .edit {
  color: #145C9E;
}
.recent-blogs-page .recent-blogs .actions .view {
  color: rgb(148, 148, 38);
}
.recent-blogs-page .recent-blogs .authors-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.recent-blogs-page .recent-blogs .authors {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.recent-blogs-page .recent-blogs .authors > :nth-child(even) {
  background-color: #feece4;
  color: #F87C47;
}
.recent-blogs-page .recent-blogs .authors .author p,
.recent-blogs-page .recent-blogs .authors .author-name p {
  font-size: 3px;
}
.recent-blogs-page .recent-blogs .authors .author {
  height: 32px;
  width: 32px;
}
.recent-blogs-page .recent-blogs .authors .author-name {
  background-color: #e8f2fc;
  color: #145C9E;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.blog-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 12px;
  color: #666666;
}

.delete-blog-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-blog-form {
  display: grid;
  gap: 1rem;
  padding: 2rem;
  background-color: white;
  max-width: 600px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 0.5rem;
}
.delete-blog-form .delete-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.delete-blog-form .delete-buttons .delete {
  background-color: tomato;
  color: white;
}/*# sourceMappingURL=recentBlogs.css.map */